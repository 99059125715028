import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from './../common/data.service';
import { ShareService } from './../common/share.service';
import { MessageService } from './../common/message.service';
import { PopUpWindowComponent } from '../popup/popupwindow.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  searchNames = [];

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  items: any;

  private _item: string;

  get item(): string {
    return this._item;
  }

  set item(value: string) {
    this._item = value;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.items.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  searchedItem: string;
  searchData = [];
  line1 = [];
  line2 = [];
  line3 = [];
  line4 = [];
  line5 = [];
  intro = [];
  character: number;
  target: string;

  constructor(
    private ds: DataService,
    public share: ShareService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private ms: MessageService) { }

  ngOnInit(): void {
    if (!this.share.search.searchGuard) {
      this.router.navigate(['/foods-index'])
    }
    if (window.screen.width > 1024) {
      this.character = 110
    }
    else {
      this.character = 60
    }
    this.getIndex();
    this.item = this.share.search.searchedItem;
    this.searchedItem = this.share.search.searchedItem;
    this.searchItems(); // reset and set based on new parameter this time
  }

  getIndex() {
    if (this.share.search.items && this.share.search.items.length > 0) {
      this.items = this.share.search.items.map((item) => { return item['Name'] });
    }
    else {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
        {
          ProcedureName: 'GetIndexSearchName', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: '' }
          ]
        }).subscribe
        (data => {
          if (data) {
            this.items = data.map((item) => { return item['Name'] });
            this.spinner.hide();
          }
          else {
            this.spinner.hide()
          };
        });
    }
  }

  searchItems() {
    if (this.share.search.searchData && this.share.search.searchData.length > 0) {
      this.spinner.show()
      let that = this.share.search;
      this.searchData = that.searchData;
      this.searchedItem = that.searchedItem;
      let data = this.searchData;
      for (let i = 0; i < data.length; i++) {
        this.searchData[i].OtherMatches = JSON.parse(this.searchData[i].OtherMatches);
      }
      for (let i = 0; i < data.length; i++) {
        this.line1[i] = '';
        this.line2[i] = '';
        this.line3[i] = '';
        this.line4[i] = '';
        this.line5[i] = '';
        if (data[i].OtherNameEng && data[i].OtherNameEng.length > 0) {
          this.line1[i] = "It is also known as " + data[i].OtherNameEng + ". ";
        }
        if (data[i].Family && data[i].Family.length > 0) {
          this.line2[i] = "It belongs to " + data[i].Family + " family. ";
        }
        if (data[i].ScientificName && data[i].ScientificName.length > 0) {
          this.line3[i] = "Its Scientific Name is " + data[i].ScientificName + ". ";
        }
        if (data[i].Special && data[i].Special.length > 0) {
          this.line4[i] = data[i].Special;
        }
        if (data[i].OName && data[i].OName.length > 0 && data[i].ONameHindi && data[i].ONameHindi.length > 0 && data[i].OName != data[i].Name) {
          this.line5[i] = data[i].Name + " is " + data[i].OName + " (" + data[i].ONameHindi + "). ";
        }
        this.intro[i] = this.line4[i];
        if (!this.intro[i] || this.intro[i].length == 0) {
          this.intro[i] = this.line1[i] + this.line2[i] + this.line3[i];
        }
        this.intro[i] = (this.line5[i] + this.intro[i]).slice(0, this.character);
      }
      if (matchMedia('(display-mode: fullscreen)').matches || matchMedia('(display-mode: standalone)').matches || matchMedia('(display-mode: minimal-ui)').matches) {
        this.target = "_self"
      }
      else {
        this.target = "_blank"
      }
      this.spinner.hide();
    }
  }

  newSearch() {
    if (this.item && this.item.length > 2) {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
        {
          ProcedureName: 'SearchIngredientsAndHerbs', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: '' },
            { ParamName: '@SearchString', ParamValue: this.item }
          ]
        }).subscribe
        (data => {
          if (data && data.length > 0) {
            this.searchData = data;
            this.searchedItem = this.item;
            for (let i = 0; i < data.length; i++) {
              this.searchData[i].OtherMatches = JSON.parse(this.searchData[i].OtherMatches);
            }
            for (let i = 0; i < data.length; i++) {
              this.line1[i] = '';
              this.line2[i] = '';
              this.line3[i] = '';
              this.line4[i] = '';
              this.line5[i] = '';
              if (data[i].OtherNameEng && data[i].OtherNameEng.length > 0) {
                this.line1[i] = "It is also known as " + data[i].OtherNameEng + ". ";
              }
              if (data[i].Family && data[i].Family.length > 0) {
                this.line2[i] = "It belongs to " + data[i].Family + " family. ";
              }
              if (data[i].ScientificName && data[i].ScientificName.length > 0) {
                this.line3[i] = "Its Scientific Name is " + data[i].ScientificName + ". ";
              }
              if (data[i].Special && data[i].Special.length > 0) {
                this.line4[i] = data[i].Special;
              }
              if (data[i].OName && data[i].OName.length > 0 && data[i].ONameHindi && data[i].ONameHindi.length > 0 && data[i].OName != data[i].Name) {
                this.line5[i] = data[i].Name + " is " + data[i].OName + " (" + data[i].ONameHindi + "). ";
              }
              this.intro[i] = this.line4[i];
              if (!this.intro[i] || this.intro[i].length == 0) {
                this.intro[i] = this.line1[i] + this.line2[i] + this.line3[i];
              }
              this.intro[i] = (this.line5[i] + this.intro[i]).slice(0, this.character);
            }
            if (matchMedia('(display-mode: fullscreen)').matches || matchMedia('(display-mode: standalone)').matches || matchMedia('(display-mode: minimal-ui)').matches) {
              this.target = "_self"
            }
            else {
              this.target = "_blank"
            }
            this.spinner.hide();
          }
          else {
            this.share.search.searchData = [];
            this.share.search.searchedItem = this.item;
            this.share.search.searchGuard = true;
            this.searchedItem = this.item;
            this.searchData = [];
            this.spinner.hide()
          }
        });
    }
    else {
      this.ms.showMessage("Enter 3 or more letters")
    }
  }

  resetItems() {
    this.share.search.searchData = [];
    this.searchData = [];
    this.share.search.searchedItem = "";
    this.searchedItem = ""
    this.share.search.searchGuard = false;
    this.router.navigate(['/foods-index'])
  }
}
