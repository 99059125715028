<footer id="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row sidebar">
                <div class="col-12 col-lg-6 col-md-6">
                    <div style="text-align: center;">
                        <img style="width:300px; margin-bottom:10px"
                            src="assets/images/Wellness-Web-Portal-Logo.webp" />
                    </div>
                    <p style="font-size:1rem; text-align: justify;">WellnessWebPortal.com features natural therapies
                        recognized by India's Ministry of AYUSH and CAM
                        (Complementary Alternative Medicines) in USA & Europe, practiced globally across cultures &
                        generations. Exlpore About Portal for details. Subscribe our Newsletter to get updates on global
                        wellness.</p>
                </div>
                <div class="col-12 col-lg-6 col-md-6 widget social" style="text-align: center;">
                    <div class="title-block">
                        <h3 class="title">Connect with us</h3>
                    </div>
                    <div class="social-list">
                        <a class="icon icon-facebook" target="_blank"
                            href="https://www.facebook.com/WellnessWebPortal/"><i class="fab fa-facebook-f"></i></a>
                        <a class="icon icon-instagram" target="_blank"
                            href="https://www.instagram.com/wellnesswebportal/"><i class="fab fa-instagram"></i></a>
                        <a class="icon icon-x" target="_blank" href="https://x.com/wellnesswportal">𝕏</a>
                        <div class="mobile m10"><br /></div>
                        <a class="icon icon-youtube" target="_blank"
                            href="https://www.youtube.com/@wellnesswebportal"><i class="fab fa-youtube"></i></a>
                    </div>
                    <div style="font-size: 14px; margin-top: 10px">Email Us: <a href="mailto:info@wellnesswebportal.com">info@wellnesswebportal.com</a>
                    </div>

                    <div class="clearfix"></div>
                </div>
            </div>
        </div>
    </div>
    <!-- .footer-top -->

    <div class="footer-bottom">
        <div class="container">
            <div class="col-xs-12 col-sm-12 col-md-12" style="text-align: center; font-size: 14px;">
                Copyright © Wellness Web Portal, 2024
            </div>
        </div>
    </div>
    <!-- .footer-bottom -->
</footer>