import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from './../common/data.service';
import { ShareService } from './../common/share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwiperOptions } from 'swiper';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.css']
})

@Injectable({
  providedIn: 'root'
})

export class SuggestionsComponent implements OnInit {

  slug: string;
  url: string;
  config: SwiperOptions;

  details = [];
  intro = [];
  otherNames = [];

  highest = [];
  higher = [];
  high = [];
  normal = [];

  showLinks = false;

  optionsAAScore = {};
  optionsGlycemic = {};
  optionsNB = {};
  optionsCaloric = {};

  videoSlug = [];

  target: string;
  lang: string;

  constructor(private ds: DataService,
    public share: ShareService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private pageTitle: Title,
    @Inject(DOCUMENT) private doc) { }

  ngOnInit(): void {
    this.slug = this.aRoute.snapshot.params['slug'];
    let urlparam = new URLSearchParams(window.location.search);
    if (urlparam.has('lang')) {
      let lang = urlparam.get('lang')
      if (lang == 'hi') {
        this.share.home.language = 'Hindi'
        this.lang = "?lang=hi";
      }
      else {
        this.share.home.language = 'English'
        this.lang = "?lang=en";
      }
    }
    else {
      this.share.home.language = 'English'
      this.lang = "?lang=en";
    }
    this.getDetails();
  }

  goTo(i, $element) {
    window.location.href = window.location.href.replace(window.location.hash, "") + i;
    // $element.scrollIntoView({behavior: "smooth", block: "start"});
  }

  getDetails() {
    this.spinner.show()
    this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
      {
        ProcedureName: 'GetIngredientDetail', SqlParamsArr: [
          { ParamName: '@Slug', ParamValue: this.slug }
        ]
      }).subscribe
      (data => {
        if (data) {
          this.details = data;
          this.share.home.name = data[0].Name;
          this.getIngredientIntro();
          this.getIngredientOLN();
          this.createLinkForCanonicalURL(data[0].Source);
          if (this.details[0].Links && this.details[0].Links.length > 0) {
            for (let i = 0; i < this.details[0].Links.length; i++) {
              if (matchMedia('(display-mode: fullscreen)').matches || matchMedia('(display-mode: standalone)').matches || matchMedia('(display-mode: minimal-ui)').matches) {
                this.details[0].Links[i].Link = this.details[0].Links[i].Link + "false";
                this.details[0].Links[i].Link = this.sanitizer.bypassSecurityTrustResourceUrl(this.details[0].Links[i].Link);
                this.target = "_self";
              }
              else {
                this.details[0].Links[i].Link = this.details[0].Links[i].Link + "true";
                this.details[0].Links[i].Link = this.sanitizer.bypassSecurityTrustResourceUrl(this.details[0].Links[i].Link);
                this.target = "_blank";
              }
              this.showLinks = true;
            }
          };
          if (this.details[0].Videos[0].MediaPath && this.details[0].Videos.length > 0) {
            for (let j = 0; j < this.details[0].Videos.length; j++) {
              this.videoSlug[j] = (this.details[0].Videos[j].Title.split(" ").join("-")).toLowerCase();
            }
          };
          if (this.share.home.language == 'Hindi') {
            this.pageTitle.setTitle(data[0].NameHindi + " के स्वास्थ्य लाभ और वो सब कुछ, जो आपको जानना चाहिए - WellnessWebPortal.com");
          }
          else {
            this.pageTitle.setTitle(data[0].Name + ": Health Benefits and everything that you need to know about - WellnessWebPortal.com");
          };
          this.getCaloricRatio();
          this.getGlycemicLoad();
          this.spinner.hide()
        }
        else {
          this.spinner.hide()
          this.router.navigate(['/not-found'], { skipLocationChange: true })
        };
      });
  }

  getIngredientIntro() {
    this.spinner.show()
    this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
      {
        ProcedureName: 'GetIngredientIntro', SqlParamsArr: [
          { ParamName: '@Slug', ParamValue: this.slug }
        ]
      }).subscribe
      (data => {
        if (data) {
          this.intro = data;
          if (data[0].NutrientSources[0].Nutrient && data[0].NutrientSources.length > 0) {
            this.highest = data[0].NutrientSources.filter((item) => item.Valency == 4);
            this.higher = data[0].NutrientSources.filter((item) => item.Valency == 3);
            this.high = data[0].NutrientSources.filter((item) => item.Valency == 2);
            this.normal = data[0].NutrientSources.filter((item) => item.Valency == 1);
          }
          this.spinner.hide()
        }
        else {
          this.spinner.hide()
        };
      });
  }

  getIngredientOLN() {
    this.spinner.show()
    this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
      {
        ProcedureName: 'GetIngredientOtherLangNames', SqlParamsArr: [
          { ParamName: '@Slug', ParamValue: this.slug }
        ]
      }).subscribe
      (data => {
        if (data) {
          this.otherNames = data;
          this.spinner.hide()
        }
        else {
          this.spinner.hide()
        };
      });
  }

  createLinkForCanonicalURL(Source) {
    if (document.getElementById('can')) {
      document.getElementById('can').remove();
    }
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('id', 'can');
    link.setAttribute('href', "https://foodandherbs.wellnesswebportal.com/foods/" + this.slug + this.lang);
  }

  getSwiperConfig(): SwiperOptions {
    var cont = document.getElementById("cont").offsetWidth - 40;
    var width = Math.round(cont / 140);
    var config: SwiperOptions = {
      direction: 'horizontal',
      slidesPerView: width,
      slidesPerGroup: 1,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true
      },
      speed: 1000,
      loop: false,
      loopFillGroupWithBlank: true,
      spaceBetween: 10 // Space between each Item
    };

    return this.config = config;
  }

  getCaloricRatio() {
    this.optionsCaloric = {
      backgroundColor: "transparent",
      color: ["#00CC00", "#CC0000", "#6666FF"],
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)',
      },
      legend: {
        orient: 'horizontal',
        top: 'top',
        data: [
          { value: this.details[0].CarbsRatio, name: 'Carbs' },
          { value: this.details[0].FatsRatio, name: 'Fats' },
          { value: this.details[0].ProteinRatio, name: 'Protein' },
        ],
        textStyle: {
          color: "blac",
        },
      },
      series: [
        {
          name: 'Caloric Ratio',
          type: 'pie',
          radius: '70%',
          responsive: false,
          center: ['50%', '50%'],
          data: [
            { value: this.details[0].CarbsRatio, name: 'Carbs' },
            { value: this.details[0].FatsRatio, name: 'Fats' },
            { value: this.details[0].ProteinRatio, name: 'Protein' },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "silver",
            },
          },
          label: false,
          labelLine: false
        },
      ],
    };
  }

  getGlycemicLoad() {
    this.optionsGlycemic = {
      backgroundColor: "transparent",
      color: [this.getGLColor(), "rgb(220,220,220)"],
      tooltip: false,
      legend: {
        orient: 'horizontal',
        top: 'top',
        data: ['Glycemic Load'],
        textStyle: {
          color: "blac",
        },
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: '70%',
          responsive: false,
          center: ['50%', '50%'],
          data: [
            { value: this.details[0].GlycemicLoad, name: 'Glycemic Load' },
            { value: 250 - this.details[0].GlycemicLoad, name: 'Total' },
          ],
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "silver",
            },
          },
          label: false,
          labelLine: false
        },
      ],
    };
  }

  getGLColor(): any {
    if (this.details[0].GlycemicLoad && this.details[0].GlycemicLoad <= 10) {
      return "#00CC00"
    }
    else if (this.details[0].GlycemicLoad && this.details[0].GlycemicLoad >= 11 && this.details[0].GlycemicLoad <= 19) {
      return "#FFC000"
    }
    else if (this.details[0].GlycemicLoad && this.details[0].GlycemicLoad >= 20) {
      return "#CC0000";
    }
  }
}