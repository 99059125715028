<app-header></app-header>
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0)" size="medium" color="#fff" type="ball-spin-clockwise">
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p class="loadP">Welcome to the<br />World of Wellness</p>
</ngx-spinner>
<div *ngFor="let item of details; let i = index;">
    <div class="container">
        <h1 *ngIf="share.home.language == 'English'"><span style="color:#03A9F4">{{item.Name}}</span><br /><span style="font-size: 0.8em;">Health Benefits and everything that you need to know about
                {{item.Name}}</span></h1>
        <h1 *ngIf="share.home.language == 'Hindi'"><span style="color:#03A9F4">{{item.NameHindi}}</span><br /><span style="font-size: 0.8em;">{{item.NameHindi}} के स्वास्थ्य लाभ और वो सब कुछ, जो आपको जानना चाहिए</span>
        </h1>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <img class="ingImg" [src]="'assets/images/nutrients/' + item.Image" />
                <div class="containerDivBlock">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">Contents</h2>
                        <ul class="contents">
                            <li *ngIf="item.Intro && item.Intro?.length > 0">
                                <a (click)="goTo('#what-is-' + item.Slug)">What is {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Intro && item.Intro?.length > 0">
                                <a (click)="goTo('#what-are-functions-of-' + item.Slug)">What are the functions of
                                    {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Links && item.Links?.length > 0">
                                <a (click)="goTo('#products-related-to-' + item.Slug + '-on-amazon')">Products Related
                                    to {{item.Name}} on Amazon</a>
                            </li>
                            <li *ngIf="item.Diseases && item.Diseases?.length > 0">
                                <a (click)="goTo('#what-diseases-are-caused-by-the-imbalance-of-' + item.Slug)">What diseases are caused by the imbalance of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="veg && veg?.length > 0">
                                <a (click)="goTo('#what-are-the-vegetables-rich-in-' + item.Slug)">What are the
                                    Vegetables rich in {{item.Name}}?</a>
                            </li>
                            <li *ngIf="fruits && fruits?.length > 0">
                                <a (click)="goTo('#what-are-the-fruits-rich-in-' + item.Slug)">What are the Fruits rich
                                    in {{item.Name}}?</a>
                            </li>
                            <li *ngIf="spices && spices?.length > 0">
                                <a (click)="goTo('#what-are-the-spices-rich-in-' + item.Slug)">What are the Spices rich
                                    in {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Herbs && item.Herbs?.length > 0">
                                <a (click)="goTo('#what-are-the-herbs-rich-in-' + item.Slug)">What are the Herbs rich in
                                    {{item.Name}}?</a>
                            </li>
                            <li *ngIf="grains && grains?.length > 0">
                                <a (click)="goTo('#what-are-the-grains-rich-in-' + item.Slug)">What are the Grains rich
                                    in {{item.Name}}?</a>
                            </li>
                            <li *ngIf="pulses && pulses?.length > 0">
                                <a (click)="goTo('#what-are-the-pulses-rich-in-' + item.Slug)">What are the Pulses rich
                                    in {{item.Name}}?</a>
                            </li>
                            <li *ngIf="dairy && dairy?.length > 0">
                                <a (click)="goTo('#what-are-the-dairy-products-rich-in-' + item.Slug)">What are the
                                    Dairy Products rich in {{item.Name}}?</a>
                            </li>
                        </ul>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">विषय-सूची</h2>
                        <ul class="contents">
                            <li *ngIf="item.Intro && item.Intro?.length > 0">
                                <a (click)="goTo('#what-is-' + item.Slug)">{{item.NameHindi}} किसे कहते हैं?</a>
                            </li>
                            <li *ngIf="item.Intro && item.Intro?.length > 0">
                                <a (click)="goTo('#what-are-functions-of-' + item.Slug)">{{item.NameHindi}} के शरीर में
                                    क्या कार्य हैं?</a>
                            </li>
                            <li *ngIf="item.Links && item.Links?.length > 0">
                                <a (click)="goTo('#products-related-to-' + item.Slug + '-on-amazon')">एमाज़ॉन पर
                                    {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स</a>
                            </li>
                            <li *ngIf="item.Diseases && item.Diseases?.length > 0">
                                <a (click)="goTo('#what-diseases-are-caused-by-the-imbalance-of-' + item.Slug)">{{item.NameHindi}} के असंतुलन से शरीर में कौन से रोग होते हैं?</a>
                            </li>
                            <li *ngIf="veg && veg?.length > 0">
                                <a (click)="goTo('#what-are-the-vegetables-rich-in-' + item.Slug)">कौन सी सब्जियां
                                    {{item.NameHindi}} से भरपूर होती हैं?</a>
                            </li>
                            <li *ngIf="fruits && fruits?.length > 0">
                                <a (click)="goTo('#what-are-the-fruits-rich-in-' + item.Slug)">कौन से फल
                                    {{item.NameHindi}} से भरपूर होते हैं?</a>
                            </li>
                            <li *ngIf="spices && spices?.length > 0">
                                <a (click)="goTo('#what-are-the-spices-rich-in-' + item.Slug)">कौन से मसाले
                                    {{item.NameHindi}} से भरपूर होते हैं?</a>
                            </li>
                            <li *ngIf="item.Herbs && item.Herbs?.length > 0">
                                <a (click)="goTo('#what-are-the-herbs-rich-in-' + item.Slug)">कौन सी जड़ी-बूटी
                                    {{item.NameHindi}} से भरपूर होती हैं?</a>
                            </li>
                            <li *ngIf="grains && grains?.length > 0">
                                <a (click)="goTo('#what-are-the-grains-rich-in-' + item.Slug)">कौन से अनाज
                                    {{item.NameHindi}} से भरपूर होते हैं?</a>
                            </li>
                            <li *ngIf="pulses && pulses?.length > 0">
                                <a (click)="goTo('#what-are-the-pulses-rich-in-' + item.Slug)">कौन सी दालें
                                    {{item.NameHindi}} से भरपूर होती हैं?</a>
                            </li>
                            <li *ngIf="dairy && dairy?.length > 0">
                                <a (click)="goTo('#what-are-the-dairy-products-rich-in-' + item.Slug)">कौन से डेयरी
                                    उत्पाद {{item.NameHindi}} से भरपूर होते हैं?</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr *ngIf="item.Intro && item.Intro?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="item.Intro && item.Intro?.length > 0" [id]="'what-is-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What is {{item.Name}}?</h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">{{item.NameHindi}} किसे कहते हैं?
                    </h2>
                    <div class="matter" *ngIf="share.home.language == 'English'" [innerHTML]="item.Intro"></div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'" [innerHTML]="item.IntroHindi"></div>
                </div>
                <hr *ngIf="item.Functions && item.Functions?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="item.Functions && item.Functions?.length > 0" [id]="'what-are-functions-of-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the functions of {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">{{item.NameHindi}} के शरीर में क्या कार्य हैं?</h2>
                    <div class="matter" *ngIf="share.home.language == 'English'" [innerHTML]="item.Functions"></div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'" [innerHTML]="item.FunctionsHindi"></div>
                </div>
                <hr id="cont" *ngIf="item.Links && item.Links?.length > 0" style="margin: 10px 0 !important">
                <div [id]="'products-related-to-' + item.Slug + '-on-amazon'" *ngIf="item.Links && item.Links?.length > 0 && showLinks" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Products Related to {{item.Name}} on Amazon</h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">एमाज़ॉन पर {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स</h2>
                    <swiper [config]="getSwiperConfig()">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let link of item.Links; let j = index;">
                                <iframe style="width: 120px; height: 240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" [src]="link.Link"></iframe>
                            </div>
                        </div>
                    </swiper>
                </div>
                <hr *ngIf="item.Diseases && item.Diseases?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="item.Diseases && item.Diseases?.length > 0" [id]="'what-diseases-are-caused-by-the-imbalance-of-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What diseases are caused by the imbalance of {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">{{item.NameHindi}} के असंतुलन से शरीर में कौन से रोग होते हैं?</h2>
                    <div *ngFor="let si of item.Diseases; let j = index" class="card">
                        <div class="card-header" style="font-weight: bold;">
                            <span *ngIf="share.home.language == 'English'">{{si.Disease}}</span>
                            <span *ngIf="share.home.language == 'Hindi'">{{si.DiseaseHindi}}</span>
                        </div>
                        <div *ngIf="share.home.language == 'English' && si.Intro && si.Intro?.length > 0" class="card-body" [innerHTML]="si.Intro"></div>
                        <div *ngIf="share.home.language == 'Hindi' && si.Intro && si.Intro?.length > 0" class="card-body" [innerHTML]="si.IntroHindi"></div>
                    </div>
                </div>

                <!-- VEG - START -->
                <hr *ngIf="veg && veg?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="veg && veg?.length > 0" [id]="'what-are-the-vegetables-rich-in-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the Vegetables rich in {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">कौन सी सब्जियां {{item.NameHindi}} से भरपूर होती हैं?</h2>
                    <div class="itemBlock">
                        <div *ngFor="let si of veg; let i = index" class="richBlock">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + si.Slug + lang" [target]="target">
                                <img [src]="'assets/images/ingredients-thumbnails/' + si.Image" />
                                <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                                <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- VEG - END -->
                <!-- FRUITS - START -->
                <hr *ngIf="fruits && fruits?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="fruits && fruits?.length > 0" [id]="'what-are-the-fruits-rich-in-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the Fruits rich in {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">कौन से फल {{item.NameHindi}} से भरपूर होते हैं?</h2>
                    <div class="itemBlock">
                        <div *ngFor="let si of fruits; let i = index" class="richBlock">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + si.Slug + lang" [target]="target">
                                <img [src]="'assets/images/ingredients-thumbnails/' + si.Image" />
                                <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                                <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- FRUITS - END -->
                <!-- SPICES - START -->
                <hr *ngIf="spices && spices?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="spices && spices?.length > 0" [id]="'what-are-the-spices-rich-in-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the Spices rich in {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">कौन से मसाले {{item.NameHindi}} से भरपूर होते हैं?</h2>
                    <div class="itemBlock">
                        <div *ngFor="let si of spices; let i = index" class="richBlock">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + si.Slug + lang" [target]="target">
                                <img [src]="'assets/images/ingredients-thumbnails/' + si.Image" />
                                <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                                <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- SPICES - END -->
                <!-- HERBS - START -->
                <hr *ngIf="item.Herbs && item.Herbs?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="item.Herbs && item.Herbs?.length > 0" [id]="'what-are-the-herbs-rich-in-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the Herbs rich in {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">कौन सी जड़ी-बूटी {{item.NameHindi}} से भरपूर होती हैं?</h2>
                    <div class="itemBlock">
                        <div *ngFor="let si of item.Herbs; let i = index" class="richBlock">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/herbs/' + si.Slug + lang" [target]="target">
                                <img [src]="'assets/images/herbs-thumbnails/' + si.Image" />
                                <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                                <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- HERBS - END -->
                <!-- GRAINS - START -->
                <hr *ngIf="grains && grains?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="grains && grains?.length > 0" [id]="'what-are-the-grains-rich-in-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the Grains rich in {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">कौन से अनाज {{item.NameHindi}} से भरपूर होते हैं?</h2>
                    <div class="itemBlock">
                        <div *ngFor="let si of grains; let i = index" class="richBlock">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + si.Slug + lang" [target]="target">
                                <img [src]="'assets/images/ingredients-thumbnails/' + si.Image" />
                                <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                                <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- GRAINS - END -->
                <!-- PULSES - START -->
                <hr *ngIf="pulses && pulses?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="pulses && pulses?.length > 0" [id]="'what-are-the-pulses-rich-in-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the Pulses rich in {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">कौन सी दालें {{item.NameHindi}} से भरपूर होती हैं?</h2>
                    <div class="itemBlock">
                        <div *ngFor="let si of pulses; let i = index" class="richBlock">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + si.Slug + lang" [target]="target">
                                <img [src]="'assets/images/ingredients-thumbnails/' + si.Image" />
                                <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                                <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- PULSES - END -->
                <!-- DAIRY - START -->
                <hr *ngIf="dairy && dairy?.length > 0" style="margin: 10px 0 !important">
                <div *ngIf="dairy && dairy?.length > 0" [id]="'what-are-the-dairy-products-rich-in-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What are the Dairy Products rich in {{item.Name}}?
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">कौन से डेयरी उत्पाद {{item.NameHindi}} से भरपूर होते हैं?</h2>
                    <div class="itemBlock">
                        <div *ngFor="let si of dairy; let i = index" class="richBlock">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + si.Slug + lang" [target]="target">
                                <img [src]="'assets/images/ingredients-thumbnails/' + si.Image" />
                                <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                                <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                            </a>
                        </div>
                    </div>
                </div>
                <!-- DAIRY - END -->
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <app-daily-product></app-daily-product>
                <app-daily-post></app-daily-post>
            </div>
        </div>
    </div>
    <div *ngIf="item.Links && item.Links?.length > 0" class="container">
        <hr style="margin: 10px 0 !important">
        <div class="containerDiv">
            <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Products Related to {{item.Name}} on Amazon
            </h2>
            <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">एमाज़ॉन पर {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स
            </h2>
            <span *ngFor="let link of item.Links;">
                <iframe style="width:120px;height:240px;margin: 0 9px 10px" marginwidth="0" marginheight="0"
                    scrolling="no" frameborder="0" [src]="link.Link"></iframe>
            </span>
        </div>
    </div>
    <!-- <div *ngIf="item.Matches && item.Matches?.length > 0" class="container">
        <hr style="margin: 10px 0 !important">
        <div class="containerDiv">
            <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Other Nutrients you may like to know about
            </h2>
            <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">अन्य न्यूट्रिएंट्स जिनके बारे में आप जानना चाहेंगे
            </h2>
            <div class="itemBlock">
                <div *ngFor="let si of item.Matches; let i = index" class="suggestionsBlock">
                    <a [href]="'https://foodandherbs.wellnesswebportal.com/nutrients/' + si.Slug + lang" [target]="target">
                        <img [src]="'assets/images/nutrients-thumbnails/' + si.Image" />
                        <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                        <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                    </a>
                </div>
            </div>
        </div>
    </div> -->
</div>
<app-scroll-top></app-scroll-top>
<app-footer></app-footer>