<app-header></app-header>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p class="loadP">Welcome to the<br />World of Wellness</p>
</ngx-spinner>
<div class="container90 containerDiv">
    <form class="form-inline" (ngSubmit)="newSearch()">
        <input type="text" class="form-control mb-2" [ngbTypeahead]="search" [(ngModel)]="item" name="item" (focus)="focus$.next($event.target.value)" (click)="click$.next($event.target.value)" #instance="ngbTypeahead" placeholder="Search..." />
        <button type="submit" class="btn btn-primary mb-2"><i class="fas fa-search"></i><span class="desktop">&nbsp;
                Search</span></button>
        <button type="reset" (click)="resetItems()" class="btn btn-warning mb-2"><i class="fas fa-times"></i><span
                class="desktop">&nbsp; Clear</span></button>
    </form>
    <div *ngIf="searchData && searchData?.length > 0">
        <h1>{{searchData?.length}} search results for "{{searchedItem}}"</h1>
        <div class="itemBlock">
            <div *ngFor="let item of searchData; let i = index" class="foodBlock">
                <div class="headBox">
                    <h6>{{item.Name}}
                        <span *ngIf="item.Name?.length > 0 && item.NameHindi?.length > 0"> | </span> {{item.NameHindi}}
                    </h6>
                </div>
                <div class="items">
                    <div class="imgBox" *ngIf="item.Type == 'Foods'">
                        <img [src]="'assets/images/ingredients-thumbnails/' + item.Image" />
                    </div>
                    <div class="imgBox" *ngIf="item.Type == 'Herbs'">
                        <img [src]="'assets/images/herbs-thumbnails/' + item.Image" />
                    </div>
                    <div class="txtBox">
                        <p *ngIf="intro[i]?.length > 0"><span [innerHTML]="intro[i]"></span>...</p>
                        <div class="langBtn" *ngIf="item.Type == 'Foods'">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + item.Slug + '?lang=hi'" [target]="target">हिन्दी</a>
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + item.Slug + '?lang=en'" [target]="target">English</a>
                        </div>
                        <div class="langBtn" *ngIf="item.Type == 'Herbs'">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/herbs/' + item.Slug + '?lang=hi'" [target]="target">हिन्दी</a>
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/herbs/' + item.Slug + '?lang=en'" [target]="target">English</a>
                        </div>
                    </div>
                </div>
                <div class="otherMatches" *ngIf="item.OtherMatches?.length > 1">
                    Other Matching Names:
                    <ul>
                        <ng-container *ngFor="let si of item.OtherMatches">
                            <li *ngIf="item.Name != si.Name">
                                {{si.Name}} | {{si.NameHindi}}
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="(!searchData || searchData?.length == 0)">
        <h1>No search results for "{{searchedItem}}"</h1>
    </div>
</div>
<app-scroll-top></app-scroll-top>
<app-footer></app-footer>