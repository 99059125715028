<nav class="navbar navbar-light bg-light">
    <div class="container logo">
        <a>
            <span class="emblemstylea">Aums</span><span class="emblemstylew">Wow</span>
            <div class="tagline"><span>Well to do</span></div>
        </a>
    </div>
</nav>
<div class="imageBox"></div>
<div class="container" style="text-align: center;">
    <h1>Error 404 - Page Not Found</h1>
    <button class="btn btn-warning" style="margin-top: 20px; width: 50%;" (click)="goBack()">&laquo;&nbsp;Back</button>
</div>