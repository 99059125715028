<app-header></app-header>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p class="loadP">Welcome to the<br />World of Wellness</p>
</ngx-spinner>
<div class="container90 containerDiv">
    <app-search-bar></app-search-bar>
    <div>
        <h1 style="color: silver">
            <span class="activeTab"><a style="color: silver" [routerLink]="['/foods-index', 'starting-with-A']">Foods Index</a></span> |
            <span class="activeTab"><a>Herbs Index</a></span></h1>
    </div>
    <div class="alphabetsBlock">
        <button *ngFor="let btn of alphabets; let i = index;" [ngClass]="{'alphabetsActive': btn == filterBy}" [routerLink]="['/herbs-index', 'starting-with-' + btn]" class="alphabets">{{btn}}</button>
    </div>
    <div class="itemBlock">
        <ng-container *ngIf="filteredIndex && filteredIndex?.length > 0">
            <div *ngFor="let item of filteredIndex; let i = index" class="foodBlock">
                <div class="headBox">
                    <h6>{{item.Name}}
                        <span *ngIf="item.Name?.length > 0 && item.NameHindi?.length > 0"> | </span> {{item.NameHindi}}
                    </h6>
                </div>
                <div class="items">
                    <div class="imgBox">
                        <img [src]="'assets/images/herbs-thumbnails/' + item.Image" />
                    </div>
                    <div class="txtBox">
                        <p *ngIf="intro[i]?.length > 0"><span [innerHTML]="intro[i]"></span>...</p>
                        <div class="langBtn">
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/herbs/' + item.Slug + '?lang=hi'" [target]="target">हिन्दी</a>
                            <a [href]="'https://foodandherbs.wellnesswebportal.com/herbs/' + item.Slug + '?lang=en'" [target]="target">English</a>
                        </div>
                    </div>
                </div>
                <div class="otherMatches" *ngIf="item.OtherMatches?.length > 1">
                    Other Names starting with {{item.Initial}}:
                    <ul>
                        <ng-container *ngFor="let si of item.OtherMatches">
                            <li *ngIf="item.Name != si.Name && item.NameHindi != si.NameHindi">
                                {{si.Name}} | {{si.NameHindi}}
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </div>
        </ng-container>
        <div *ngIf="!filteredIndex || filteredIndex?.length == 0">
            No data found.
        </div>
    </div>
</div>
<app-scroll-top></app-scroll-top>
<app-footer></app-footer>