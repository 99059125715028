import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from './../common/data.service';
import { ShareService } from './../common/share.service';
import { MessageService } from './../common/message.service';
import { PopUpWindowComponent } from '../popup/popupwindow.component';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.css'],
  entryComponents: [PopUpWindowComponent],
})
export class SearchBarComponent implements OnInit {

  searchNames = [];

  @ViewChild('instance') instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  items: any;

  private _item: string;

  get item(): string {
    return this._item;
  }

  set item(value: string) {
    this._item = value;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 3 ? []
        : this.items.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  constructor(
    private ds: DataService,
    public share: ShareService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private ms: MessageService
  ) { }

  ngOnInit(): void {
    this.getIndex();

    this.aRoute.queryParams.subscribe(params => {
      this.item = params.query;
    });
  }

  getIndex() {
    if (this.share.search.items && this.share.search.items.length > 0) {
      this.items = this.share.search.items.map((item) => { return item['Name'] });
    }
    else {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
        {
          ProcedureName: 'GetIndexSearchName', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: '' }
          ]
        }).subscribe
        (data => {
          if (data) {
            this.items = data.map((item) => { return item['Name'] });
            this.spinner.hide();
          }
          else {
            this.spinner.hide()
          };
        });
    }
  }

  searchItems() {
    if (this.item && this.item.length > 2) {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
        {
          ProcedureName: 'SearchIngredientsAndHerbs', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: '' },
            { ParamName: '@SearchString', ParamValue: this.item }
          ]
        }).subscribe
        (data => {
          if (data && data.length > 0) {
            this.share.search.searchData = data;
            this.share.search.searchedItem = this.item;
            this.share.search.searchGuard = true;
            this.router.navigate(['search'], { skipLocationChange: false })
            this.spinner.hide();
          }
          else {
            this.share.search.searchData = [];
            this.share.search.searchedItem = this.item;
            this.share.search.searchGuard = true;
            this.router.navigate(['search'], { skipLocationChange: false })
            this.spinner.hide()
          }
        });
    }
    else {
      this.ms.showMessage("Enter 3 or more letters")
    }
  }

  resetItems() {
    this.share.search.searchData = [];
    this.share.search.searchedItem = "";
    this.share.search.searchGuard = false;
    this.router.navigate(['/foods-index'])
  }
}