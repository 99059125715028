import { Injectable, ViewContainerRef } from '@angular/core';
import { Attribute } from '@angular/compiler';
import { Header, Home, Search } from './interfaces'



@Injectable()
export class ShareService {
    public tableSrc: any;

    public header: Header = {
        images: []
    };

    public home: Home = {
        language: "English",
        activeTab: "Foods",
        name: "",
        foods: [],
        herbs: []
    }

    public search: Search = {
        items: [],
        searchedItem: "",
        searchData: [],
        searchGuard: false
    }
}