import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from './../common/data.service';
import { ShareService } from './../common/share.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {

  menuOption: string = window.location.href;
  current = window.location.href;
  target: string;

  showMenu = false;

  aboutToggle = false;
  therapiesToggle = false;
  therapiesLToggle = false;
  seekerToggle = false;
  findCenterToggle = false;
  successStoriesToggle = false;
  providersToggle = false;
  callToActionToggle = false;
  askAQuestionToggle = false;

  constructor(private ds: DataService,
    public share: ShareService,
    private spinner: NgxSpinnerService,
    private router: Router) { }

  async ngOnInit() {
    await this.getHeaderImages();

    if (matchMedia('(display-mode: fullscreen)').matches || matchMedia('(display-mode: standalone)').matches || matchMedia('(display-mode: minimal-ui)').matches) {
      this.target = "_self";
    }
    else {
      this.target = "_blank";
    }

    await this.setSelectedMenu();
  }

  showMobileMenu() {
    this.showMenu = true;
    document.getElementById("mm").classList.add("rotated");
  }

  closeMobileMenu() {
    this.showMenu = false;
    document.getElementById("mm").classList.remove("rotated");
    this.aboutToggle = false;
    this.therapiesToggle = false;
    this.therapiesLToggle = false;
    this.seekerToggle = false;
    this.findCenterToggle = false;
    this.successStoriesToggle = false;
    this.providersToggle = false;
    this.callToActionToggle = false;
    this.askAQuestionToggle = false;
  }

  toggleAbout() {
    this.aboutToggle = !this.aboutToggle;
    if (this.aboutToggle) {
      this.therapiesToggle = false;
      this.therapiesLToggle = false;
      this.seekerToggle = false;
      this.findCenterToggle = false;
      this.successStoriesToggle = false;
      this.providersToggle = false;
      this.callToActionToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleTherapies() {
    this.therapiesToggle = !this.therapiesToggle;
    if (this.therapiesToggle) {
      this.aboutToggle = false;
      this.therapiesLToggle = false;
      this.seekerToggle = false;
      this.findCenterToggle = false;
      this.successStoriesToggle = false;
      this.providersToggle = false;
      this.callToActionToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleTherapiesL() {
    this.therapiesLToggle = !this.therapiesLToggle;
    if (this.therapiesLToggle) {
      this.aboutToggle = false;
      this.seekerToggle = false;
      this.findCenterToggle = false;
      this.successStoriesToggle = false;
      this.providersToggle = false;
      this.callToActionToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleSeeker() {
    this.seekerToggle = !this.seekerToggle;
    if (this.seekerToggle) {
      this.aboutToggle = false;
      this.therapiesToggle = false;
      this.therapiesLToggle = false;
      this.findCenterToggle = false;
      this.successStoriesToggle = false;
      this.providersToggle = false;
      this.callToActionToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleFindCenter() {
    this.findCenterToggle = !this.findCenterToggle;
    if (this.findCenterToggle) {
      this.aboutToggle = false;
      this.therapiesToggle = false;
      this.therapiesLToggle = false;
      this.successStoriesToggle = false;
      this.providersToggle = false;
      this.callToActionToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleSuccessStories() {
    this.successStoriesToggle = !this.successStoriesToggle;
    if (this.successStoriesToggle) {
      this.aboutToggle = false;
      this.therapiesToggle = false;
      this.therapiesLToggle = false;
      this.findCenterToggle = false;
      this.providersToggle = false;
      this.callToActionToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleProviders() {
    this.providersToggle = !this.providersToggle;
    if (this.providersToggle) {
      this.aboutToggle = false;
      this.therapiesToggle = false;
      this.therapiesLToggle = false;
      this.seekerToggle = false;
      this.findCenterToggle = false;
      this.successStoriesToggle = false;
      this.callToActionToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleCallToAction() {
    this.callToActionToggle = !this.callToActionToggle;
    if (this.callToActionToggle) {
      this.aboutToggle = false;
      this.therapiesToggle = false;
      this.therapiesLToggle = false;
      this.seekerToggle = false;
      this.findCenterToggle = false;
      this.successStoriesToggle = false;
      this.providersToggle = false;
      this.askAQuestionToggle = false;
    }
  }

  toggleAskQuestion() {
    this.askAQuestionToggle = !this.askAQuestionToggle;
    if (this.askAQuestionToggle) {
      this.aboutToggle = false;
      this.therapiesToggle = false;
      this.therapiesLToggle = false;
      this.seekerToggle = false;
      this.findCenterToggle = false;
      this.successStoriesToggle = false;
      this.providersToggle = false;
    }
  }

  setSelectedMenu() {
    setTimeout(() => {
      if (this.share.home.name && this.share.home.name.length > 0) {
        this.menuOption = this.current;
      }
      else {
        this.menuOption = "https://foodandherbs.wellnesswebportal.com/";
      }
    }, 1000);
  }

  getHeaderImages() {
    this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostAnon",
      {
        ProcedureName: 'GetSuggestionsSiteBannerImagesForToday', SqlParamsArr: []
      }).subscribe
      (data => {
        if (data) {
          this.share.header.images = data;
        }
        else this.share.header.images = [];
      });
  }

  goToMenu() {
    window.location.href = this.menuOption;
  }
}