<app-header></app-header>
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p class="loadP">Welcome to the<br />World of Wellness</p>
</ngx-spinner>
<div *ngFor="let item of details; let i = index;">
    <div class="container">
        <h1 *ngIf="share.home.language == 'English'"><span style="color:#03A9F4">{{item.Name}}</span><br /><span style="font-size: 0.8em;">Health Benefits and everything that you need to know about
                {{item.Name}}</span></h1>
        <h1 *ngIf="share.home.language == 'Hindi'"><span style="color:#03A9F4">{{item.NameHindi}}</span><br /><span style="font-size: 0.8em;">{{item.NameHindi}} के स्वास्थ्य लाभ और वो सब कुछ, जो आपको जानना चाहिए</span>
        </h1>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <img class="ingImg" [src]="'assets/images/ingredients/' + item.Image" />
                <div class="containerDivBlock">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">Contents</h2>
                        <ul class="contents">
                            <li *ngIf="(item.Intro && item.Intro?.length > 0) || (intro && intro?.length > 0)">
                                <a (click)="goTo('#what-is-' + item.Slug)">What is {{item.Name}}?</a>
                            </li>
                            <!-- <li *ngIf="item.Links && item.Links?.length > 0">
                                <a (click)="goTo('#products-related-to-' + item.Slug + '-on-amazon')">Products Related
                                    to {{item.Name}} on Amazon</a>
                            </li> -->
                            <li *ngIf="item.Optimal && item.Optimal > 0">
                                <a (click)="goTo('#how-much-' + item.Slug + '-in-a-day')">How much
                                    {{item.Name}} to <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">eat</span>
                                    <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">drink</span>
                                    <span *ngIf="item.Name.toLowerCase().includes('oil')">consume</span> in a day?</a>
                            </li>
                            <li *ngIf="item.Mode && item.Mode?.length > 0">
                                <a (click)="goTo('#how-to-consume-' + item.Slug)">How to <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">eat</span>
                                    <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">drink</span>
                                    <span *ngIf="item.Name.toLowerCase().includes('oil')">consume</span>
                                    {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.pHLevel && item.pHLevel > 0">
                                <a (click)="goTo('#what-is-the-ph-level-of-' + item.Slug)">What is the pH Level of
                                    {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.AAScore && item.AAScore >= 0">
                                <a (click)="goTo('#what-is-the-amino-acid-score-of-' + item.Slug)">What is the
                                    Amino-Acid Score of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.CompletenessScore && item.CompletenessScore > 0">
                                <a (click)="goTo('#what-is-the-completeness-score-of-' + item.Slug)">What is the
                                    Completeness Score of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.CarbsRatio && item.CarbsRatio >= 0">
                                <a (click)="goTo('#what-is-the-caloric-ratio-of-' + item.Slug)">What is the
                                    Caloric Ratio of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.GlycemicLoad && item.GlycemicLoad > 0">
                                <a (click)="goTo('#what-is-the-glycemic-load-of-' + item.Slug)">What is the
                                    Glycemic Load of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.GlycemicIndex && item.GlycemicIndex > 0">
                                <a (click)="goTo('#what-is-the-glycemic-index-of-' + item.Slug)">What is the
                                    Glycemic Index of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Purine && item.Purine > 0">
                                <a (click)="goTo('#what-is-the-purine-level-of-' + item.Slug)">What is the
                                    Purine Level of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Synonyms && item.Synonyms?.length > 0">
                                <a (click)="goTo('#other-names-of-' + item.Slug)">Other Names of {{item.Name}}</a>
                            </li>
                            <li *ngIf="otherNames && otherNames?.length > 0">
                                <a (click)="goTo('#different-names-of-' + item.Slug)">What are the different names of
                                    {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Videos[0].MediaPath && item.Videos?.length > 0"><a (click)="goTo('#videos-about-' + item.Slug, vid)">Videos on {{item.Name}}</a>
                                <ul>
                                    <li *ngFor="let video of item.Videos; let j = index"><a (click)="goTo('#' + videoSlug[j])">{{video.Title}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">विषय-सूची</h2>
                        <ul class="contents">
                            <li *ngIf="(item.Intro && item.Intro?.length > 0) || (intro && intro?.length > 0)">
                                <a (click)="goTo('#what-is-' + item.Slug)">{{item.NameHindi}} किसे कहते हैं?</a>
                            </li>
                            <!-- <li *ngIf="item.Links && item.Links?.length > 0">
                                <a (click)="goTo('#products-related-to-' + item.Slug + '-on-amazon')">एमाज़ॉन पर
                                    {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स</a>
                            </li> -->
                            <li *ngIf="item.Optimal && item.Optimal > 0">
                                <a (click)="goTo('#how-much-' + item.Slug + '-in-a-day')">प्रतिदिन कितनी मात्रा में
                                    {{item.NameHindi}} <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">खाएँ</span>
                                    <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">पिएँ</span>
                                    <span *ngIf="item.Name.toLowerCase().includes('oil')">सेवन करें</span>?</a>
                            </li>
                            <li *ngIf="item.Mode && item.Mode?.length > 0">
                                <a (click)="goTo('#how-to-consume-' + item.Slug)">{{item.NameHindi}} कैसे <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">खाएँ</span>
                                    <span
                                        *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">पिएँ</span>
                                    <span *ngIf="item.Name.toLowerCase().includes('oil')">सेवन करें</span>?</a>
                            </li>
                            <li *ngIf="item.pHLevel && item.pHLevel > 0">
                                <a (click)="goTo('#what-is-the-ph-level-of-' + item.Slug)">{{item.NameHindi}} का पी.एच.
                                    (pH) लेवल कितना है?</a>
                            </li>
                            <li *ngIf="item.AAScore && item.AAScore >= 0">
                                <a (click)="goTo('#what-is-the-amino-acid-score-of-' + item.Slug)">{{item.NameHindi}} का
                                    एमिनो एसिड स्कोर कितना है?</a>
                            </li>
                            <li *ngIf="item.CompletenessScore && item.CompletenessScore > 0">
                                <a (click)="goTo('#what-is-the-completeness-score-of-' + item.Slug)">{{item.NameHindi}}
                                    का पूर्णता स्कोर कितना है?</a>
                            </li>
                            <li *ngIf="item.CarbsRatio && item.CarbsRatio >= 0">
                                <a (click)="goTo('#what-is-the-caloric-ratio-of-' + item.Slug)">{{item.NameHindi}} का
                                    कैलोरिक अनुपात कितना है?</a>
                            </li>
                            <li *ngIf="item.GlycemicLoad && item.GlycemicLoad > 0">
                                <a (click)="goTo('#what-is-the-glycemic-load-of-' + item.Slug)">{{item.NameHindi}} का
                                    ग्लाइसेमिक लोड कितना है?</a>
                            </li>
                            <li *ngIf="item.GlycemicIndex && item.GlycemicIndex > 0">
                                <a (click)="goTo('#what-is-the-glycemic-index-of-' + item.Slug)">{{item.NameHindi}} का
                                    ग्लाइसेमिक इंडेक्स कितना है?</a>
                            </li>
                            <li *ngIf="item.Purine && item.Purine > 0">
                                <a (click)="goTo('#what-is-the-purine-level-of-' + item.Slug)">{{item.NameHindi}} का
                                    प्यूरीन लेवल कितना है?</a>
                            </li>
                            <li *ngIf="item.Synonyms && item.Synonyms?.length > 0">
                                <a (click)="goTo('#other-names-of-' + item.Slug)">{{item.NameHindi}} के अन्य नाम</a>
                            </li>
                            <li *ngIf="otherNames && otherNames?.length > 0">
                                <a (click)="goTo('#different-names-of-' + item.Slug)">{{item.NameHindi}} के विभिन्न नाम
                                    क्या हैं?</a>
                            </li>
                            <li *ngIf="item.Videos[0].MediaPath && item.Videos?.length > 0"><a (click)="goTo('#videos-about-' + item.Slug)">{{item.NameHindi}} पर वीडियोज़</a>
                                <ul>
                                    <li *ngFor="let video of item.Videos; let j = index"><a (click)="goTo('#' + videoSlug[j])">{{video.TitleHindi}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr *ngIf="item.Intro && item.Intro?.length > 0" style="margin: 10px 0 !important">
                <div [id]="'what-is-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What is {{item.Name}}?</h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">{{item.NameHindi}} किसे कहते हैं?
                    </h2>
                    <ng-container *ngIf="intro && intro?.length > 0">
                        <div *ngFor="let si of intro; let j = index;">
                            <div class="matter" *ngIf="share.home.language == 'English'">
                                <p *ngIf="si.Special && si.Special?.length > 0" [innerHTML]="si.Special">
                                </p>
                                <p>
                                    <span *ngIf="!si.Special || si.Special?.length == 0">
                                        {{item.Name}}<span *ngIf="si.OtherNameEng && si.OtherNameEng?.length > 0">, also
                                            known as {{si.OtherNameEng}} etc.</span>
                                    <span *ngIf="si.OtherNameEng && si.OtherNameEng?.length > 0 && si.Family && si.Family?.length > 0">;</span>
                                    <span *ngIf="si.Family && si.Family?.length > 0"> belongs to {{si.Family}}
                                            family</span>.
                                    <span *ngIf="si.ScientificName && si.ScientificName?.length > 0">Its Scientific
                                            Name is {{si.ScientificName}}. </span>
                                    </span>
                                    <span *ngIf="si.NameSansRom && si.NameSansRom?.length > 0 || si.NameHinRom && si.NameHinRom?.length > 0">It
                                        is called </span>
                                    <span *ngIf="si.NameSansRom && si.NameSansRom?.length > 0">{{si.NameSansRom}} in
                                        Sanskrit</span>
                                    <span *ngIf="si.NameSansRom && si.NameSansRom?.length > 0 && si.NameHinRom && si.NameHinRom?.length > 0">
                                        and </span>
                                    <span *ngIf="si.NameHinRom && si.NameHinRom?.length > 0">{{si.NameHinRom}} in
                                        Hindi.</span>
                                </p>
                                <p *ngIf="!si.Special || si.Special?.length == 0">
                                    <span *ngIf="si.Tastes[0].Taste && si.Tastes?.length > 0">The {{item.Name}} tastes
                                        <span *ngFor="let taste of si.Tastes; let k = index;">
                                            {{taste.Taste}}<span
                                                *ngIf="k+1 != si.Tastes?.length && k+2 != si.Tastes?.length">, </span>
                                    <span *ngIf="k+2 == si.Tastes?.length"> and </span></span>.
                                    </span>

                                    <span *ngIf="si.EdibleParts[0].Part && si.EdibleParts?.length">
                                        Its
                                        <span *ngFor="let part of si.EdibleParts; let k = index;">
                                            {{part.Part}}<span
                                                *ngIf="k+1 != si.EdibleParts?.length && k+2 != si.EdibleParts?.length">,
                                            </span>
                                    <span *ngIf="k+2 == si.EdibleParts?.length"> and </span>
                                    </span>
                                    can be consumed as Food or used for Medicinal purposes.</span>
                                </p>
                                <p *ngIf="si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    The major nutrients available in {{item.Name}} are as follows:
                                </p>
                                <ul *ngIf="si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    <li *ngIf="highest && highest?.length > 0">Super-Rich in <span *ngFor="let mp of highest; let k = index;">
                                            {{mp.Nutrient}}<span
                                                *ngIf="k+1 != highest?.length && k+2 != highest?.length">,
                                            </span><span *ngIf="k+2 == highest?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="higher && higher?.length > 0">Most-Rich in <span *ngFor="let mp of higher; let k = index;">
                                            {{mp.Nutrient}}<span
                                                *ngIf="k+1 != higher?.length && k+2 != higher?.length">,
                                            </span><span *ngIf="k+2 == higher?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="high && high?.length > 0">High-Rich in <span *ngFor="let mp of high; let k = index;">
                                            {{mp.Nutrient}}<span *ngIf="k+1 != high?.length && k+2 != high?.length">,
                                            </span><span *ngIf="k+2 == high?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="normal && normal?.length > 0">Rich in <span *ngFor="let mp of normal; let k = index;">
                                            {{mp.Nutrient}}<span
                                                *ngIf="k+1 != normal?.length && k+2 != normal?.length">,
                                            </span><span *ngIf="k+2 == normal?.length"> and </span></span>
                                    </li>
                                </ul>
                                <p>
                                    Upon consuming {{item.Optimal}}<span *ngIf="item.QtyUnit == 1">g</span><span *ngIf="item.QtyUnit == 2">ml</span> of {{item.Name}}, our body gets
                                    <span>{{si.Calories? si.Calories : 0}} kCal ({{si.CaloriesP? si.CaloriesP : 0}}%
                                        DV) of Calories, </span>
                                    <span>{{si.Carbs? si.Carbs : 0}}g ({{si.CarbsP? si.CarbsP : 0}}% DV) of
                                        Carbohydrates, </span>
                                    <span>{{si.Fats? si.Fats : 0}}g ({{si.FatsP? si.FatsP : 0}}% DV) of Fats and
                                    </span>
                                    <span>{{si.Protein? si.Protein : 0}}g ({{si.ProteinP? si.ProteinP : 0}}% DV) of
                                        Protein.</span>
                                </p>
                                <p style="font-size: small;text-align: right;font-style: italic;">
                                    <a placement="top" popoverTitle="% Daily Value" [ngbPopover]="dv">%DV = Percentage
                                        Daily Value.</a>
                                </p>
                                <ng-template #dv>
                                    <p>Percentage Daily Value is the quantity of each nutrient present in the Food.</p>
                                    <p>Percentage Daily Value is different and calculated separately for each nutrient on the basis of the Recommended Daily Intake of that Nutrient.</p>
                                    <p style="font-size: small;font-style: italic;">This percentage of DV is defined by FDA (US).</p>
                                    <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                                </ng-template>
                            </div>
                            <div class="matter" *ngIf="share.home.language == 'Hindi'">
                                <p *ngIf="si.SpecialHindi && si.SpecialHindi?.length > 0" [innerHTML]="si.SpecialHindi">
                                </p>
                                <p>
                                    <span *ngIf="!si.SpecialHindi || si.SpecialHindi?.length == 0">
                                        {{item.NameHindi}}<span
                                            *ngIf="si.OtherNamesHin && si.OtherNamesHin?.length > 0">,
                                            जिसे
                                            {{si.OtherNamesHin}} आदि नामों से भी जाना जाता है</span>
                                    <span *ngIf="si.OtherNamesHin && si.OtherNamesHin?.length > 0 && si.ScientificNameHindi && si.ScientificNameHindi?.length > 0">;
                                        </span>
                                    <span *ngIf="si.ScientificNameHindi && si.ScientificNameHindi?.length > 0"> का
                                            वैज्ञानिक-नाम {{si.ScientificNameHindi}}<span
                                                *ngIf="si.ScientificName != si.ScientificNameHindi">
                                                ({{si.ScientificName}})</span> है</span>।
                                    <span *ngIf="si.FamilyHindi && si.FamilyHindi?.length > 0">यह
                                            {{si.FamilyHindi}}<span *ngIf="si.Family != si.FamilyHindi">
                                                ({{si.Family}})</span> परिवार से सम्बन्धित है।
                                    </span>
                                    </span>
                                    इसे <span *ngIf="si.NameSansDev && si.NameSansDev?.length > 0">संस्कृत में
                                        {{si.NameSansDev}}</span>
                                    <span *ngIf="si.NameSansDev && si.NameSansDev?.length > 0 && si.NameEngDev && si.NameEngDev?.length > 0">
                                        एवम् </span>
                                    <span *ngIf="si.NameEngDev && si.NameEngDev?.length"> अंग्रेजी में
                                        {{si.NameEngDev}}</span> कहते हैं।
                                </p>
                                <p *ngIf="!si.SpecialHindi || si.SpecialHindi?.length == 0">
                                    <span *ngIf="si.Tastes[0].Taste && si.Tastes?.length > 0">{{item.NameHindi}} का
                                        स्वाद
                                        <span *ngFor="let taste of si.Tastes; let k = index;">
                                            {{taste.TasteHindi}}<span
                                                *ngIf="k+1 != si.Tastes?.length && k+2 != si.Tastes?.length">,
                                            </span><span *ngIf="k+2 == si.Tastes?.length"> और </span></span> होता है।
                                    </span>
                                    <span *ngIf="si.EdibleParts[0].Part && si.EdibleParts?.length > 0">
                                        इसके
                                        <span *ngFor="let part of si.EdibleParts; let k = index;">
                                            {{part.PartHindi}}<span
                                                *ngIf="k+1 != si.EdibleParts?.length && k+2 != si.EdibleParts?.length">,
                                            </span><span *ngIf="k+2 == si.EdibleParts?.length"> और </span></span>
                                    को भोजन अथवा औषधि के रूप में उपयोग किया जा सकता है।</span>
                                </p>
                                <p *ngIf="si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    {{item.NameHindi}} में उपलब्ध प्रमुख न्यूट्रिएंट्स इस प्रकार हैं:
                                </p>
                                <ul *ngIf="si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    <li *ngIf="highest && highest?.length > 0"><span *ngFor="let mp of highest; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != highest?.length && k+2 != highest?.length">,
                                            </span><span *ngIf="k+2 == highest?.length"> और </span></span> बहुत अधिक मात्रा में
                                    </li>
                                    <li *ngIf="higher && higher?.length > 0"><span *ngFor="let mp of higher; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != higher?.length && k+2 != higher?.length">,
                                            </span><span *ngIf="k+2 == higher?.length"> और </span></span> अधिक मात्रा में
                                    </li>
                                    <li *ngIf="high && high?.length > 0"><span *ngFor="let mp of high; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != high?.length && k+2 != high?.length">,
                                            </span><span *ngIf="k+2 == high?.length"> और </span></span> उचित मात्रा में
                                    </li>
                                    <li *ngIf="normal && normal?.length > 0"><span *ngFor="let mp of normal; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != normal?.length && k+2 != normal?.length">,
                                            </span><span *ngIf="k+2 == normal?.length"> और </span></span> सामान्य मात्रा में
                                    </li>
                                </ul>
                                <p>
                                    {{item.NameHindi}} की {{item.Optimal}} <span *ngIf="item.QtyUnit == 1">ग्राम</span><span *ngIf="item.QtyUnit == 2">मिली.</span> मात्रा का सेवन करने पर
                                    <span>{{si.Calories? si.Calories : 0}} kCal ({{si.CaloriesP? si.CaloriesP : 0}}%
                                        डी.वी.) कैलोरीज़, </span>
                                    <span>{{si.Carbs? si.Carbs : 0}} ग्राम ({{si.CarbsP? si.CarbsP : 0}}% डी.वी.)
                                        कार्बोहाइड्रेट्स, </span>
                                    <span>{{si.Fats? si.Fats : 0}} ग्राम ({{si.FatsP? si.FatsP : 0}}% डी.वी.) फैट्स
                                        और </span>
                                    <span>{{si.Protein? si.Protein : 0}} ग्राम ({{si.ProteinP? si.ProteinP : 0}}%
                                        डी.वी.) प्रोटीन </span> हमारे शरीर को प्राप्त हो जाता है।
                                </p>
                                <p style="font-size: small;text-align: right;font-style: italic;">
                                    <a placement="top" popoverTitle="% डेली वैल्यू" [ngbPopover]="dv">%डी.वी. = डेली
                                        वैल्यू प्रतिशत</a>
                                </p>
                                <ng-template #dv>
                                    <p>डेली वैल्यू प्रतिशत भोजन में मौजूद प्रत्येक न्यूट्रिएंट की मात्रा है।</p>
                                    <p>डेली वैल्यू प्रतिशत प्रत्येक न्यूट्रिएंट के लिए अलग-अलग होती है व उसकी गणना भी उसके रेकमेंडेड डेली इन्टेक के आधार पर की जाती है।</p>
                                    <p style="font-size: small;font-style: italic;">ये सभी डेली वैल्यू प्रतिशत एफ.डी.ए. (यू.एस.) द्वारा निर्धारित की गयी हैं।</p>
                                    <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                                </ng-template>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!intro || intro?.length == 0">
                        <div class="matter" *ngIf="share.home.language == 'English'" [innerHTML]="item.Intro">
                        </div>
                        <div class="matter" *ngIf="share.home.language == 'Hindi'" [innerHTML]="item.IntroHindi">
                        </div>
                    </ng-container>
                </div>
                <!-- <hr id="cont" *ngIf="item.Links && item.Links?.length > 0" style="margin: 10px 0 !important">
                <div [id]="'products-related-to-' + item.Slug + '-on-amazon'" *ngIf="item.Links && item.Links?.length > 0 && showLinks" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Products Related to {{item.Name}} on Amazon</h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">एमाज़ॉन पर {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स</h2>
                    <swiper [config]="getSwiperConfig()">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let link of item.Links; let j = index;">
                                <iframe style="width: 120px; height: 240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" [src]="link.Link"></iframe>
                            </div>
                        </div>
                    </swiper>
                </div> -->
                <div [id]="'how-much-' + item.Slug + '-in-a-day'">
                    <hr *ngIf="item.Quantity && item.Quantity?.length > 0 || item.QuantityHindi && item.QuantityHindi?.length > 0" style="margin: 10px 0 !important;">
                    <div id="howMuch" *ngIf="item.Quantity && item.Quantity?.length > 0 || item.QuantityHindi && item.QuantityHindi?.length > 0" class="containerDiv">
                        <div class="matter" *ngIf="share.home.language == 'English'">
                            <h2 style="color: #03A9F4">How much {{item.Name}} to <span *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">eat</span>
                                <span *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">drink</span>
                                <span *ngIf="item.Name.toLowerCase().includes('oil')">consume</span> in a day?</h2>
                            <div [innerHTML]="item.Quantity"></div>
                        </div>
                        <div class="matter" *ngIf="share.home.language == 'Hindi'">
                            <h2 style="color: #03A9F4">प्रतिदिन कितनी मात्रा में {{item.NameHindi}} <span *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">खाएँ</span>
                                <span *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">पिएँ</span>
                                <span *ngIf="item.Name.toLowerCase().includes('oil')">सेवन करें</span>?</h2>
                            <div [innerHTML]="item.QuantityHindi"></div>
                        </div>
                    </div>
                    <hr *ngIf="item.Optimal && item.Optimal > 0 && (!item.Quantity || item.Quantity?.length == 0)" style="margin: 10px 0 !important;">
                    <div *ngIf="item.Optimal && item.Optimal > 0 && (!item.Quantity || item.Quantity?.length == 0)" class="containerDiv">
                        <div class="matter" *ngIf="share.home.language == 'English'">
                            <h2 style="color: #03A9F4">How much {{item.Name}} to <span *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">eat</span>
                                <span *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">drink</span>
                                <span *ngIf="item.Name.toLowerCase().includes('oil')">consume</span> in a day?</h2>
                            <div>Optimal Quantity: {{item.Optimal}} <span *ngIf="item.QtyUnit == 1">g</span><span *ngIf="item.QtyUnit == 2">ml</span></div>
                            <div>Maximum Quantity: {{item.Maximum}} <span *ngIf="item.QtyUnit == 1">g</span><span *ngIf="item.QtyUnit == 2">ml</span></div>
                        </div>
                        <div class="matter" *ngIf="share.home.language == 'Hindi'">
                            <h2 style="color: #03A9F4">प्रतिदिन कितनी मात्रा में {{item.NameHindi}} <span *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">खाएँ</span>
                                <span *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">पिएँ</span>
                                <span *ngIf="item.Name.toLowerCase().includes('oil')">सेवन करें</span>?</h2>
                            <div>उचित मात्रा: {{item.Optimal}} <span *ngIf="item.QtyUnit == 1">ग्राम</span><span *ngIf="item.QtyUnit == 2">मिली.</span></div>
                            <div>अधिकतम मात्रा: {{item.Maximum}} <span *ngIf="item.QtyUnit == 1">ग्राम</span><span *ngIf="item.QtyUnit == 2">मिली.</span></div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="item.Mode && item.Mode?.length > 0 || item.ModeHindi && item.ModeHindi?.length > 0" style="margin: 10px 0 !important;">
                <div [id]="'how-to-consume-' + item.Slug" *ngIf="item.Mode && item.Mode?.length > 0 || item.ModeHindi && item.ModeHindi?.length > 0" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">How to <span *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">eat</span>
                            <span *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">drink</span>
                            <span *ngIf="item.Name.toLowerCase().includes('oil')">consume</span> {{item.Name}}?
                        </h2>
                        <div [innerHTML]="item.Mode"></div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} कैसे <span *ngIf="item.QtyUnit && item.QtyUnit == 1 && !item.Name.toLowerCase().includes('oil')">खाएँ</span>
                            <span *ngIf="item.QtyUnit && item.QtyUnit == 2 && !item.Name.toLowerCase().includes('oil')">पिएँ</span>
                            <span *ngIf="item.Name.toLowerCase().includes('oil')">सेवन करें</span>?</h2>
                        <div [innerHTML]="item.ModeHindi"></div>
                    </div>
                </div>
                <hr *ngIf="item.pHLevel && item.pHLevel > 0" style="margin: 10px 0 !important;">
                <div *ngIf="item.pHLevel && item.pHLevel > 0" [id]="'what-is-the-ph-level-of-' + item.Slug" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">What is the pH Level of {{item.Name}}?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                Acidic
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                Neutral
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                Alkaline
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/pH-scale.png" />
                        <input type="range" min="100" max="1400" [value]="item.pHLevel * 100" class="slider" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">pH Level: {{item.pHLevel}} / 14
                                <span *ngIf="item.pHLevel >= 5.3">(Low-Acidic)</span>
                                <span *ngIf="item.pHLevel > 4.4 && item.pHLevel < 5.3">(Medium-Acidic)</span>
                                <span *ngIf="item.pHLevel > 3.7 && item.pHLevel <= 4.4">(Acidic)</span>
                                <span *ngIf="item.pHLevel <= 3.7">(High-Acidic)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="pH Level" [ngbPopover]="pH">Know, what this is?</button>
                            <ng-template #pH>
                                <p>The meaning of pH is potential of Hydrogen or power of Hydrogen. pH Scale used to measure the acidic or basic (alkaline) nature of aqueous or liquid solutions from a range of 0 to 14, where 7 means the Neutral.</p>
                                <p>The blood in human body has a pH Level ranging from 7.35 to 7.45, that's slightly alkaline.
                                </p>
                                <p>The food items are also categorized into Low-Acidic, Medium-Acidic, Acidic and High-Acidic. Food plays an important in the maintaining the pH balance in the body. Generally, Low-Acidic Diet is considered healthier.</p>
                                <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} का पी.एच. (pH) लेवल कितना है?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                Acidic
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                Neutral
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                Alkaline
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/pH-scale.png" />
                        <input type="range" min="100" max="1400" [value]="item.pHLevel * 100" class="slider" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">पी.एच. (pH) लेवल: {{item.pHLevel}} / 14
                                <span *ngIf="item.pHLevel >= 5.3">(कम-अम्लीय)</span>
                                <span *ngIf="item.pHLevel > 4.4 && item.pHLevel < 5.3">(मध्यम-अम्लीय)</span>
                                <span *ngIf="item.pHLevel > 3.7 && item.pHLevel <= 4.4">(अम्लीय)</span>
                                <span *ngIf="item.pHLevel <= 3.7">(उच्च-अम्लीय)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="पी.एच. (pH) लेवल" [ngbPopover]="pH">जानिए ये क्या होता है?</button>
                            <ng-template #pH>
                                <p>पी.एच. (pH) का अर्थ हाइड्रोजन की क्षमता या हाइड्रोजन की शक्ति है। पी.एच. (pH) स्केल 0 से 14 की श्रेणी में जलीय या तरल घोलों के अम्लीय या क्षारीय प्रकृति को मापने के लिए उपयोग किया जाता है, जहां 7 का अर्थ न्यूट्रल है।</p>
                                <p>मानव शरीर में रक्त का पी.एच. (pH) स्तर 7.35 से 7.45 तक होता है, जो थोड़ा क्षारीय है।
                                </p>
                                <p>भोजन पदार्थों को भी कम-अम्लीय, मध्यम-अम्लीय, अम्लीय और उच्च-अम्लीय में वर्गीकृत किया जाता है। भोजन शरीर में पी.एच. (pH) संतुलन बनाए रखने में महत्वपूर्ण भूमिका निभाता है। आमतौर पर, कम अम्लीय आहार स्वास्थ्यवर्धक माना जाता
                                    है।
                                </p>
                                <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <hr *ngIf="item.AAScore && item.AAScore > 0" style="margin: 10px 0 !important;">
                <div *ngIf="item.AAScore && item.AAScore > 0" [id]="'what-is-the-amino-acid-score-of-' + item.Slug" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">What is the Amino-Acid Score of {{item.Name}}?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                80
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                160
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/amino-acid-scale.png" />
                        <input type="range" min="0" max="160" [value]="item.AAScore" class="sliderAA" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">Amino-Acid Score: {{item.AAScore}} / 160
                                <span *ngIf="item.AAScore < 80">(Low)</span>
                                <span *ngIf="item.AAScore >= 80 && item.AAScore <= 99">(Good)</span>
                                <span *ngIf="item.AAScore >= 100">(High)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="Amino Acid Score" [ngbPopover]="aa">Know, what this is?</button>
                            <ng-template #aa>
                                <p>Amino Acids are called the building-blocks of the human body. 75% of the body is made up of proteins, which is formed by amino acids.</p>
                                <p>Amino Acids are responsible for most of the major bodily processes start from digestion to healing of the body, hence are crucial for the human health.</p>
                                <p>Two types of Amino Acids are there, essential and non-essential. Essential Amino Acids can not be made in body and therefore need to be obtained from the Food sources.
                                </p>
                                <p>Amino Acid Score helps in determining the quality of the Protein present in the Food item. Higher the Amino Acid Score, better is the quality of the protein.</p>
                                <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} का एमिनो एसिड स्कोर कितना है?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                80
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                160
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/amino-acid-scale.png" />
                        <input type="range" min="0" max="160" [value]="item.AAScore" class="sliderAA" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">एमिनो एसिड स्कोर: {{item.AAScore}} / 160
                                <span *ngIf="item.AAScore < 80">(कम)</span>
                                <span *ngIf="item.AAScore >= 80 && item.AAScore <= 99">(अच्छा)</span>
                                <span *ngIf="item.AAScore >= 100">(बहुत अच्छा)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="एमिनो एसिड स्कोर" [ngbPopover]="aa">जानिए ये क्या होता है?</button>
                            <ng-template #aa>
                                <p>एमिनो एसिड को मानव शरीर का बिल्डिंग-ब्लॉक कहा जाता है। 75% मानव शरीर प्रोटीन से बना है, जो (प्रोटीन) कि एमिनो एसिड द्वारा बनता है।</p>
                                <p>एमिनो एसिड शरीर के पाचन से लेकर शरीर की चिकित्सा तक अधिकांश प्रमुख शारीरिक प्रक्रियाओं के लिए जिम्मेदार हैं, इसलिए मानव स्वास्थ्य के लिए महत्वपूर्ण हैं।</p>
                                <p>दो प्रकार के एमिनो एसिड होते हैं, एसेंशियल और नॉन-एसेंशियल। एसेंशियल एमिनो एसिड शरीर में नहीं बनाया जा सकता है और इसलिए इन्हे भोजन पदार्थों से प्राप्त करना अति आवश्यक है।
                                </p>
                                <p>एमिनो एसिड स्कोर भोजन पदार्थों में मौजूद प्रोटीन की गुणवत्ता बताता है। उच्च एमिनो एसिड स्कोर यानी बेहतर प्रोटीन गुणवत्ता।</p>
                                <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <hr *ngIf="item.CompletenessScore && item.CompletenessScore > 0" style="margin: 10px 0 !important;">
                <div *ngIf="item.CompletenessScore && item.CompletenessScore > 0" [id]="'what-is-the-completeness-score-of-' + item.Slug" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">What is the Completeness Score of {{item.Name}}?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                50
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                100
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/completeness-score-scale.png" />
                        <input type="range" min="1" max="100" [value]="item.CompletenessScore" class="sliderCS" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">Completeness Score: {{item.CompletenessScore}} / 100
                                <span *ngIf="item.CompletenessScore < 35">(Low)</span>
                                <span *ngIf="item.CompletenessScore >= 35 && item.CompletenessScore <= 59">(Medium)</span>
                                <span *ngIf="item.CompletenessScore >= 60">(High)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="Completeness Score" [ngbPopover]="nb">Know, what this is?</button>
                            <ng-template #nb>
                                <p>Completeness Score represents the Nutritional Balance of the Food Item. As per the standards laid by the FDA (US) for a Balanced Diet, Completeness Score is calculated based on the availability and proportionate of Nutrients
                                    present in the Food Item.</p>
                                <p>High Completeness Score means that most of the nutrients required for a balanced diet are available in the Food Item.</p>
                                <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} का पूर्णता स्कोर कितना है?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                50
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                100
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/completeness-score-scale.png" />
                        <input type="range" min="1" max="100" [value]="item.CompletenessScore" class="sliderCS" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">पूर्णता स्कोर: {{item.CompletenessScore}} / 100
                                <span *ngIf="item.CompletenessScore < 35">(कम)</span>
                                <span *ngIf="item.CompletenessScore >= 35 && item.CompletenessScore <= 59">(मध्यम)</span>
                                <span *ngIf="item.CompletenessScore >= 60">(उच्च)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="पूर्णता स्कोर" [ngbPopover]="nb">जानिए ये क्या होता है?</button>
                            <ng-template #nb>
                                <p>पूर्णता स्कोर भोजन पदार्थों का न्यूट्रिएंट बैलेंस दर्शाता है। एफ.डी.ए. (यू.एस.) द्वारा संतुलित-आहार के लिए निर्धारित मानकों के अनुसार, भोजन पदार्थों में मौजूद न्यूट्रिएंट्स की उपलब्धता और अनुपात के आधार पर पूर्णता स्कोर
                                    की गणना की जाती है।</p>
                                <p>उच्च पूर्णता स्कोर का मतलब है कि संतुलित-आहार के लिए आवश्यक अधिकांश न्यूट्रिएंट्स भोजन पदार्थ में उपलब्ध हैं।</p>
                                <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <hr style="margin: 10px 0 !important">
                <div class="row">
                    <div *ngIf="share.home.language == 'English'" class="col-lg-6 col-md-6 col-12 graph3">
                        <div [id]="'what-is-the-caloric-ratio-of-' + item.Slug" class="containerDiv">
                            <h2 style="color: #03A9F4">Caloric Ratio</h2>
                            <h6 style="margin-bottom: 20px;">Carbs: {{item.CarbsRatio}} | Fats: {{item.FatsRatio}} | Protein: {{item.ProteinRatio}}</h6>
                            <div echarts [options]="optionsCaloric" class="echart" [loading]="true"></div>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="Caloric Ratio" [ngbPopover]="crp">Know, what this is?</button>
                            <ng-template #crp>
                                <p>Caloric Ratio is the proportionate of the Calories obtained from Carbohydrates, Fats and Protein in the Food Item.</p>
                                <p>A proper Caloric Ratio needs to be maintained for a healthy balanced diet.</p>
                                <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div *ngIf="share.home.language == 'Hindi'" class="col-lg-6 col-md-6 col-12 graph3">
                        <div [id]="'what-is-the-caloric-ratio-of-' + item.Slug" class="containerDiv">
                            <h2 style="color: #03A9F4">कैलोरिक अनुपात</h2>
                            <h6 style="margin-bottom: 20px;">कार्ब्स: {{item.CarbsRatio}} | फैट्स: {{item.FatsRatio}} | प्रोटीन: {{item.ProteinRatio}}</h6>
                            <div echarts [options]="optionsCaloric" class="echart" [loading]="true"></div>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="कैलोरिक अनुपात" [ngbPopover]="crp">जानिए ये क्या होता है?</button>
                            <ng-template #crp>
                                <p>कैलोरिक अनुपात भोजन पदार्थ में कार्बोहाइड्रेट, फैट्स और प्रोटीन से प्राप्त कैलोरी का समानुपात है।</p>
                                <p>एक स्वस्थ बैलेंस्ड डाइट के लिए एक उचित कैलोरिक अनुपात आवश्यक है।</p>
                                <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div class="col-12 mobile">
                        <hr style="margin: 10px 0 !important">
                    </div>
                    <div *ngIf="share.home.language == 'English'" class="col-lg-6 col-md-6 col-12 graph1">
                        <div [id]="'what-is-the-glycemic-load-of-' + item.Slug" class="containerDiv">
                            <h2 style="color: #03A9F4">Glycemic Load</h2>
                            <h6 style="margin-bottom: 20px;">Load: {{item.GlycemicLoad}} / 250
                                <span *ngIf="item.GlycemicLoad <= 10">(Low)</span>
                                <span *ngIf="item.GlycemicLoad >= 11 && item.GlycemicLoad <= 19">(Medium)</span>
                                <span *ngIf="item.GlycemicLoad >= 20">(High)</span>
                            </h6>
                            <div echarts [options]="optionsGlycemic" class="echart" [loading]="true"></div>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="Glycemic Load" [ngbPopover]="gl">Know, what this is?</button>
                            <ng-template #gl>
                                <p>Glycemic Load helps in determining the rise in Blood Glucose Levels after consuming a Food Item.</p>
                                <p>Glycemic Load varies with the Quantity and the Glycemic Index of each Item. We have calculated this Glycemic Load on basis of Optimal Quantity suggested above for this Food Item.</p>
                                <p>People suffering from Diabetes are advised food items with Low Glycemic Load.</p>
                                <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div *ngIf="share.home.language == 'Hindi'" class="col-lg-6 col-md-6 col-12 graph1">
                        <div [id]="'what-is-the-glycemic-load-of-' + item.Slug" class="containerDiv">
                            <h2 style="color: #03A9F4">ग्लाइसेमिक लोड</h2>
                            <h6 style="margin-bottom: 20px;">लोड: {{item.GlycemicLoad}} / 250
                                <span *ngIf="item.GlycemicLoad <= 10">(कम)</span>
                                <span *ngIf="item.GlycemicLoad >= 11 && item.GlycemicLoad <= 19">(मध्यम)</span>
                                <span *ngIf="item.GlycemicLoad >= 20">(उच्च)</span>
                            </h6>
                            <div echarts [options]="optionsGlycemic" class="echart" [loading]="true"></div>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="ग्लाइसेमिक लोड" [ngbPopover]="gl">जानिए ये क्या होता है?</button>
                            <ng-template #gl>
                                <p>ग्लाइसेमिक लोड से पता चलता है कि एक भोजन पदार्थ का उपभोग करने के बाद रक्त ग्लूकोज़ के स्तर में कितनी वृद्धि होती है।</p>
                                <p>ग्लाइसेमिक लोड प्रत्येक भोजन पदार्थ की मात्रा और ग्लाइसेमिक इंडेक्स के साथ बदलता है। हमने इस भोजन पदार्थ के लिए सुझाए गए ऑप्टीमल मात्रा के आधार पर इस ग्लाइसेमिक लोड की गणना की है।</p>
                                <p>मधुमेह से पीड़ित लोगों को कम ग्लाइसेमिक लोड वाले भोजन पदार्थों की सलाह दी जाती है।
                                </p>
                                <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <hr *ngIf="item.GlycemicIndex && item.GlycemicIndex > 0" style="margin: 10px 0 !important;">
                <div *ngIf="item.GlycemicIndex && item.GlycemicIndex > 0" [id]="'what-is-the-glycemic-index-of-' + item.Slug" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">What is the Glycemic Index of {{item.Name}}?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                50
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                100
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/glycemic-index-scale.png" />
                        <input type="range" min="1" max="100" [value]="item.GlycemicIndex" class="sliderGI" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">Glycemic Index: {{item.GlycemicIndex}} / 100
                                <span *ngIf="item.GlycemicIndex <= 55">(Low)</span>
                                <span *ngIf="item.GlycemicIndex > 55 && item.GlycemicIndex < 70">(Medium)</span>
                                <span *ngIf="item.GlycemicIndex >= 70">(High)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="Glycemic Index" [ngbPopover]="gi">Know, what this is?</button>
                            <ng-template #gi>
                                <p>Glycemic Index is the scoring of Food Items based on How fast that Food raises the Blood Glucose Levels.</p>
                                <p>Glycemic Index ranges from 0 – 100, where 100 is the Index of Pure Glucose. On the Glycemic Index Scale, 0 to 55 means Low Glycemic Index (GI) and Food Items with Glycemic Index above 70 are considered High GI Foods.</p>
                                <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} का ग्लाइसेमिक इंडेक्स कितना है?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                50
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                100
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/glycemic-index-scale.png" />
                        <input type="range" min="1" max="100" [value]="item.GlycemicIndex" class="sliderGI" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">ग्लाइसेमिक इंडेक्स: {{item.GlycemicIndex}} / 100
                                <span *ngIf="item.GlycemicIndex <= 55">(कम)</span>
                                <span *ngIf="item.GlycemicIndex > 55 && item.GlycemicIndex < 70">(मध्यम)</span>
                                <span *ngIf="item.GlycemicIndex >= 70">(उच्च)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="ग्लाइसेमिक इंडेक्स" [ngbPopover]="gi">जानिए ये क्या होता है?</button>
                            <ng-template #gi>
                                <p>ग्लाइसेमिक इंडेक्स भोजन पदार्थों की स्कोरिंग है कि कितनी तेजी से वह भोजन पदार्थ रक्त में ग्लूकोज़ के स्तर को बढ़ाता है।</p>
                                <p>ग्लाइसेमिक इंडेक्स 0 - 100 से होता है, जहां 100 शुद्ध ग्लूकोज़ का सूचकांक है। ग्लाइसेमिक इंडेक्स स्केल पर, 0 से 55 का मतलब लो ग्लाइसेमिक इंडेक्स (जी.आई.) और 70 से ऊपर के ग्लाइसेमिक इंडेक्स वाले भोजन पदार्थों को उच्च जी.आई.
                                    फूड्स माना जाता है।</p>
                                <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <hr *ngIf="item.Purine && item.Purine > 0" style="margin: 10px 0 !important;">
                <div *ngIf="item.Purine && item.Purine > 0" [id]="'what-is-the-purine-level-of-' + item.Slug" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">What is the Purine Level of {{item.Name}}?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                200
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                400
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/purine-level-scale.png" />
                        <input type="range" min="10" max="4000" [value]="item.Purine * 10" class="sliderPurine" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">Purine Level: {{item.Purine}} / 400
                                <span *ngIf="item.Purine <= 100">(Low)</span>
                                <span *ngIf="item.Purine > 100 && item.Purine <= 300">(Moderately High)</span>
                                <span *ngIf="item.Purine > 300">(High)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="Purine Level" [ngbPopover]="pl">Know, what this is?</button>
                            <ng-template #pl>
                                <p>Purine is a compound of nitrogen and carbon molecules combined with hydrogen molecules. Purine is found in body cells. Generally, it is good rather than harmful to the body; except in a few cases where someone is suffering
                                    from or likely to suffer from Gout disease i.e. a type of arthritis.</p>
                                <p>Fewer or more quantities of purine are also found in food items; The purine available in ingested food is converted into uric acid by body metabolism. Like other impurities, the Kidney filters out this uric acid from the
                                    bloodstream. However, if this acid is formed in excess in the body, it can accumulate in one or more joints in the form of crystals and become the main cause of gout disease. Sometimes kidney stones are also formed.</p>
                                <p>Therefore, people who are suffering from arthritis, etc., or who for any reason always want to stay free from such diseases; should choose their food based on the amount of purine available in the food and get health benefits.</p>
                                <p class="closePop">Close &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} का प्यूरीन लेवल कितना है?</h2>
                        <div class="row" style="font-weight: bold; font-size: small;">
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: left;">
                                0
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: center;">
                                200
                            </div>
                            <div class="col-4 col-lg-4 col-md-4" style="text-align: right;">
                                400
                            </div>
                        </div>
                        <img style="width: 100%;margin-bottom: 2px;" src="assets/images/purine-level-scale.png" />
                        <input type="range" min="10" max="4000" [value]="item.Purine * 10" class="sliderPurine" [disabled]="true">
                        <div style="text-align: center;">
                            <h6 style="margin: 20px 0;">प्यूरीन लेवल: {{item.Purine}} / 400
                                <span *ngIf="item.Purine <= 100">(कम)</span>
                                <span *ngIf="item.Purine > 100 && item.Purine <= 300">(मध्यम)</span>
                                <span *ngIf="item.Purine > 300">(उच्च)</span>
                            </h6>
                            <button class="btn btn-outline-secondary mr-2" placement="top" popoverTitle="प्यूरीन लेवल" [ngbPopover]="pl">जानिए ये क्या होता है?</button>
                            <ng-template #pl>
                                <p>प्यूरीन हाइड्रोजन अणुओं के साथ मिलकर नाइट्रोजन और कार्बन अणुओं का एक यौगिक है। प्यूरीन शरीर की कोशिकाओं में पाया जाता है। आमतौर पर, यह शरीर के लिए हानिकारक के बजाय अच्छा है; सिवाय कुछ मामलों में जहां कोई व्यक्ति गठिया रोग
                                    से पीड़ित है या होने की संभावना है, यानी एक प्रकार का गठिया।</p>
                                <p>भोजन पदार्थों में भी कम अथवा अधिक मात्रा में प्यूरीन पाया जाता है; ग्रहण किया गए भोजन में उपलब्ध प्यूरीन को शरीर मेटबोलिज़्म द्वारा युरिक ऐसिड में परिवर्तित कर देता है। अन्य अशुद्धियों की तरह किड्नी इस युरिक ऐसिड को भी
                                    रक्त-धारा से फ़िल्टर करके बाहर निकाल देती है। किंतु यदि यह ऐसिड शरीर में अधिक मात्रा में बन जाए तो एक या एक से अधिक जोड़ों में क्रिस्टल्ज़ के रूप में जमा हो सकता है व गठिया (गाउट) जैसी बीमारी का मुख्य कारण बन जाता है।कभी-कभी
                                    किड्नी आदि की पथरी भी बन जाती है।</p>
                                <p>अतः जो लोग गठिया आदि रोग से ग्रसित हैं अथवा जो किन्ही भी कारणों से इस प्रकार के रोगों से सदैव मुक्त रहना चाहते हैं; उनको चाहिए कि वो अपने भोजन का चयन भोजन में उपलब्ध प्यूरीन की मात्रा के आधार पर करें व स्वास्थ्य लाभ पाएँ।</p>
                                <p class="closePop">बंद करे &nbsp;<i class="fas fa-times"></i></p>
                            </ng-template>
                        </div>
                    </div>
                </div>

                <hr *ngIf="item.Synonyms && item.Synonyms?.length > 0 || item.SynonymsHindi && item.SynonymsHindi?.length > 0" style="margin: 10px 0 !important;">
                <div [id]="'other-names-of-' + item.Slug" *ngIf="item.Synonyms && item.Synonyms?.length > 0 || item.SynonymsHindi && item.SynonymsHindi?.length > 0" class="containerDivBlock">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">Other Names of {{item.Name}}</h2>
                        <div class="synonyms" [innerHTML]="item.Synonyms"></div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} के अन्य नाम</h2>
                        <div class="synonyms" [innerHTML]="item.SynonymsHindi"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <app-daily-product></app-daily-product>
                <app-daily-post></app-daily-post>
                <div [id]="'videos-about-' + item.Slug" #vid class="containerDiv" *ngIf="item.Videos[0].MediaPath && item.Videos?.length > 0">
                    <hr style="margin-top:0px">
                    <h2 *ngIf="share.home.language == 'English'" style="text-align: center;color: #03A9F4">Videos on {{item.Name}}
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="text-align: center;color: #03A9F4">
                        {{item.NameHindi}} पर वीडियोज़</h2>
                    <div *ngFor="let video of item.Videos; let j = index">
                        <div [id]="videoSlug[j]">
                            <h6 *ngIf="share.home.language == 'English'" style="margin:10px 0">{{video.Title}}</h6>
                            <h6 *ngIf="share.home.language == 'Hindi'" style="margin:10px 0">{{video.TitleHindi}}</h6>
                            <video style="width:100%" [poster]="'assets/videos/ingredients/' + video.MediaPoster" controls playsinline crossorigin="anonymous">
                                <source [src]="'assets/videos/ingredients/' + video.MediaPath" type="video/mp4" />
                            </video>
                            <div style="font-size: small; font-style: italic; color: grey">Courtesy: {{video.Source}}
                            </div>
                        </div>
                        <hr *ngIf="j+1 != item.Videos?.length">
                    </div>
                </div>
                <hr *ngIf="otherNames && otherNames?.length > 0" style="margin-top:50px">
                <div [id]="'different-names-of-' + item.Slug" *ngIf="otherNames && otherNames?.length > 0">
                    <h2 *ngIf="share.home.language == 'English'" style="text-align: center;color: #03A9F4;font-weight: normal;">Different Names of
                        <br />{{item.Name}}
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="text-align: center;color: #03A9F4;font-weight: normal;line-height: 164%;">
                        {{item.NameHindi}}<br />के विभिन्न नाम
                    </h2>
                    <div class="containerDivBlock" style="margin-top: 10px !important">
                        <div *ngFor="let name of otherNames; let k = index" style="text-align: center;">
                            <h6 style="color: #03A9F4" *ngIf="share.home.language == 'English'">
                                {{item.Name}} in {{name.Language}}
                            </h6>
                            <h6 style="color: #03A9F4" *ngIf="share.home.language == 'Hindi'">
                                {{name.LanguageHindi}} में {{item.NameHindi}} का नाम
                            </h6>
                            <div *ngFor="let sn of name.Names; let l = index;" class="otherLangNames">
                                <div>{{sn.Devanagari}}</div>
                                <div>|</div>
                                <div>{{sn.Roman}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="item.Links && item.Links?.length > 0" class="container">
        <hr style="margin: 10px 0 !important">
        <div class="containerDiv">
            <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Products Related to {{item.Name}} on Amazon
            </h2>
            <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">एमाज़ॉन पर {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स
            </h2>
            <span *ngFor="let link of item.Links;">
                <iframe style="width:120px;height:240px;margin: 0 9px 10px" marginwidth="0" marginheight="0"
                    scrolling="no" frameborder="0" [src]="link.Link"></iframe>
            </span>
        </div>
    </div> -->
    <div *ngIf="item.Matches && item.Matches?.length > 0" class="container">
        <hr style="margin: 10px 0 !important">
        <div class="containerDiv">
            <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Other Foods you may like to know about
            </h2>
            <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">अन्य भोजन पदार्थ जिनके बारे में आप जानना चाहेंगे
            </h2>
            <div class="itemBlock">
                <div *ngFor="let si of item.Matches; let i = index" class="suggestionsBlock">
                    <a [href]="'https://foodandherbs.wellnesswebportal.com/foods/' + si.Slug + lang" [target]="target">
                        <img [src]="'assets/images/ingredients-thumbnails/' + si.Image" />
                        <h6 *ngIf="share.home.language == 'English'">{{si.Name}}</h6>
                        <h6 *ngIf="share.home.language == 'Hindi'">{{si.NameHindi}}</h6>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<app-scroll-top></app-scroll-top>
<app-footer></app-footer>