import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from './../common/data.service';
import { ShareService } from './../common/share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-herbs-index',
  templateUrl: './herbs-index.component.html',
  styleUrls: ['./herbs-index.component.css']
})
export class HerbsIndexComponent implements OnInit {

  foodsIndex = [];
  alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  filteredIndex = [];
  filterBy: string;
  line1 = [];
  line2 = [];
  line3 = [];
  line4 = [];
  line5 = [];
  intro = [];
  character: number;
  target: string;

  constructor(
    private ds: DataService,
    public share: ShareService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private aRoute: ActivatedRoute) { }

  ngOnInit(): void {
    if (window.screen.width > 1024) {
      this.character = 110
    }
    else {
      this.character = 60
    }

    this.aRoute.params.subscribe(params => {
      this.filterBy = (params["startingWith"]).replace("starting-with-","");
      this.filter(); // reset and set based on new parameter this time
    });

    this.getIngredientsIndex();
  }

  getIngredientsIndex() {
    if (this.share.home.herbs && this.share.home.herbs.length > 0) {
      this.spinner.show()
      this.foodsIndex = this.share.home.herbs;
      this.filter();
      this.spinner.hide();
    }
    else {
      this.spinner.show()
      this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
        {
          ProcedureName: 'GetHerbsIndex', SqlParamsArr: [
            { ParamName: '@UserID', ParamValue: '' }
          ]
        }).subscribe
        (data => {
          if (data) {
            this.foodsIndex = data;
            this.share.home.herbs = data;
            this.filter();
            this.spinner.hide();
          }
          else {
            this.spinner.hide()
          };
        });
    }
  }

  filter() {
    this.filteredIndex = this.foodsIndex.filter((item) => item.Initial === this.filterBy);
    let data = this.filteredIndex;
    for (let i = 0; i < data.length; i++) {
      this.line1[i] = '';
      this.line2[i] = '';
      this.line3[i] = '';
      this.line4[i] = '';
      this.line5[i] = '';
      if (data[i].OtherNameEng && data[i].OtherNameEng.length > 0) {
        this.line1[i] = "It is also known as " + data[i].OtherNameEng + ". ";
      }
      if (data[i].Family && data[i].Family.length > 0) {
        this.line2[i] = "It belongs to " + data[i].Family + " family. ";
      }
      if (data[i].ScientificName && data[i].ScientificName.length > 0) {
        this.line3[i] = "Its Scientific Name is " + data[i].ScientificName + ". ";
      }
      if (data[i].Special && data[i].Special.length > 0) {
        this.line4[i] = data[i].Special;
      }
      if (data[i].OName && data[i].OName.length > 0 && data[i].ONameHindi && data[i].ONameHindi.length > 0 && data[i].OName != data[i].Name) {
        this.line5[i] = data[i].Name + " is " + data[i].OName + " (" + data[i].ONameHindi + "). ";
      }
      this.intro[i] = this.line4[i];
      if (!this.intro[i] || this.intro[i].length == 0) {
        this.intro[i] = this.line1[i] + this.line2[i] + this.line3[i];
      }
      this.intro[i] = (this.line5[i] + this.intro[i]).slice(0, this.character);
    }
    if (matchMedia('(display-mode: fullscreen)').matches || matchMedia('(display-mode: standalone)').matches || matchMedia('(display-mode: minimal-ui)').matches) {
      this.target = "_self"
    }
    else {
      this.target = "_blank"
    }
  }
}