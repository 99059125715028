<div class="containerDiv" *ngIf="products && products?.length > 0">
    <hr style="margin-top:0px">
    <h2 *ngIf="share.home.language == 'English'" style="text-align: center;color: #03A9F4">You may like it</h2>
    <h2 *ngIf="share.home.language == 'Hindi'" style="text-align: center;color: #03A9F4">आपके लिए</h2>
    <div class="media" *ngFor="let item of products; let i = index;">
        <img *ngIf="item.Type == 'Image'" [src]="'https://aumswow.com/GenericAPI/DailyProduct/' + item.MediaPath" />
        <video *ngIf="item.Type == 'Video'" [poster]="'https://aumswow.com/GenericAPI/DailyProduct/' + item.MediaPoster" playsinline controls crossorigin="anonymous">
            <source [src]="'https://aumswow.com/GenericAPI/DailyProduct/' + item.MediaPath" type="video/mp4" />
        </video>
        <a class="btn btn-sm btn-info web" *ngIf="share.home.language == 'English'" style="width:100%" [href]="item.ProductURL" target="_blank">Visit Site&nbsp;&nbsp;<i class="fas fa-external-link-alt"></i></a>
        <a class="btn btn-sm btn-info web" *ngIf="share.home.language == 'Hindi'" style="width:100%" [href]="item.ProductURL" target="_blank">साइट देखें&nbsp;&nbsp;<i class="fas fa-external-link-alt"></i></a>
        <a class="btn btn-sm btn-info app" *ngIf="share.home.language == 'English'" style="width:100%" [href]="item.ProductURL">Visit Site&nbsp;&nbsp;<i class="fas fa-external-link-alt"></i></a>
        <a class="btn btn-sm btn-info app" *ngIf="share.home.language == 'Hindi'" style="width:100%" [href]="item.ProductURL">साइट देखें&nbsp;&nbsp;<i class="fas fa-external-link-alt"></i></a>
        <hr *ngIf="i+1 != products?.length">
    </div>
</div>