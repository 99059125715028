<app-header></app-header>
<!-- <ngx-spinner bdColor="rgba(0, 0, 0, 0)" size="medium" color="#fff" type="ball-spin-clockwise">
</ngx-spinner> -->
<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p class="loadP">Welcome to the<br />World of Wellness</p>
</ngx-spinner>
<div *ngFor="let item of details; let i = index;">
    <div class="container">
        <h1 *ngIf="share.home.language == 'English'"><span style="color:#03A9F4">{{item.Name}}</span><br/><span style="font-size: 0.8em;">Health Benefits and everything that you need to know about {{item.Name}}</span></h1>
        <h1 *ngIf="share.home.language == 'Hindi'"><span style="color:#03A9F4">{{item.NameHindi}}</span><br/><span style="font-size: 0.8em;">{{item.NameHindi}} के स्वास्थ्य लाभ और वो सब कुछ, जो आपको जानना चाहिए</span></h1>
        <div class="row">
            <div class="col-lg-8 col-md-8 col-12">
                <img class="ingImg" [src]="'assets/images/herbs/' + item.Image" />
                <div class="containerDivBlock">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">Contents</h2>
                        <ul class="contents">
                            <li *ngIf="item.Intro && item.Intro?.length > 0">
                                <a (click)="goTo('#what-is-' + item.Slug)">What is {{item.Name}}?</a>
                            </li>
                            <!-- <li *ngIf="item.Links && item.Links?.length > 0">
                                <a (click)="goTo('#products-related-to-' + item.Slug + '-on-amazon')">Products Related
                                    to {{item.Name}} on Amazon</a>
                            </li> -->
                            <li *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0">
                                <a (click)="goTo('#how-to-consume-' + item.Slug)">How to consume {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0">
                                <a (click)="goTo('#how-much-' + item.Slug + '-in-a-day')">How much {{item.Name}} to
                                    consume in a day?</a>
                            </li>
                            <li *ngIf="item.Synonyms && item.Synonyms?.length > 0">
                                <a (click)="goTo('#other-names-of-' + item.Slug)">Other Names of {{item.Name}}</a>
                            </li>
                            <li *ngIf="otherNames && otherNames?.length > 0">
                                <a (click)="goTo('#different-names-of-' + item.Slug)">What are the different names of {{item.Name}}?</a>
                            </li>
                            <li *ngIf="item.Videos[0].MediaPath && item.Videos?.length > 0"><a (click)="goTo('#videos-about-' + item.Slug)">Videos on {{item.Name}}</a>
                                <ul>
                                    <li *ngFor="let video of item.Videos; let j = index"><a (click)="goTo('#' + videoSlug[j])">{{video.Title}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">विषय-सूची</h2>
                        <ul class="contents">
                            <li *ngIf="item.Intro && item.Intro?.length > 0">
                                <a (click)="goTo('#what-is-' + item.Slug)">{{item.NameHindi}} किसे कहते हैं?</a>
                            </li>
                            <!-- <li *ngIf="item.Links && item.Links?.length > 0">
                                <a (click)="goTo('#products-related-to-' + item.Slug + '-on-amazon')">एमाज़ॉन पर
                                    {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स</a>
                            </li> -->
                            <li *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0">
                                <a (click)="goTo('#how-to-consume-' + item.Slug)">{{item.NameHindi}} कैसे ग्रहण
                                    करें?</a>
                            </li>
                            <li *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0">
                                <a (click)="goTo('#how-much-' + item.Slug + '-in-a-day')">प्रतिदिन कितनी मात्रा में
                                    {{item.NameHindi}} ग्रहण करें?</a>
                            </li>
                            <li *ngIf="item.Synonyms && item.Synonyms?.length > 0">
                                <a (click)="goTo('#other-names-of-' + item.Slug)">{{item.NameHindi}} के अन्य नाम</a>
                            </li>
                            <li *ngIf="otherNames && otherNames?.length > 0">
                                <a (click)="goTo('#different-names-of-' + item.Slug)">{{item.NameHindi}} के विभिन्न नाम क्या हैं?</a>
                            </li>
                            <li *ngIf="item.Videos[0].MediaPath && item.Videos?.length > 0"><a (click)="goTo('#videos-about-' + item.Slug)">{{item.NameHindi}} पर वीडियोज़</a>
                                <ul>
                                    <li *ngFor="let video of item.Videos; let j = index"><a (click)="goTo('#' + videoSlug[j])">{{video.TitleHindi}}</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr *ngIf="item.Intro && item.Intro?.length > 0" style="margin: 10px 0 !important">
                <div [id]="'what-is-' + item.Slug" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">What is {{item.Name}}?</h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">{{item.NameHindi}} किसे कहते हैं?
                    </h2>
                    <ng-container *ngIf="intro && intro?.length > 0 && intro[0].ScientificName">
                        <div *ngFor="let si of intro; let j = index;">
                            <div class="matter" *ngIf="share.home.language == 'English'">
                                <p>
                                    {{item.Name}}<span *ngIf="si.OtherNamesHinRom && si.OtherNamesHinRom?.length > 0">, also known
                                        as {{si.OtherNamesHinRom}} etc</span>
                                    <span *ngIf="si.OtherNamesHinRom && si.OtherNamesHinRom?.length > 0 && si.Family && si.Family?.length > 0">;
                                    </span>
                                    <span *ngIf="si.Family && si.Family?.length > 0"> belongs to {{si.Family}}
                                        family</span>.&nbsp;
                                    <span *ngIf="si.ScientificName && si.ScientificName?.length > 0">Its Scientific Name is
                                        {{si.ScientificName}}. </span>
                                    <span *ngIf="si.OtherNameEng && si.OtherNameEng?.length > 0">It
                                        is called {{si.OtherNameEng}} in English.</span>
                                </p>
                                <p *ngIf="si.NameSansRom && si.NameSansRom?.length > 0">
                                    {{item.Name}} is also called {{si.NameSansRom}} in Sanskrit.
                                </p>
                                <p>
                                    <span *ngIf="si.Tastes[0].Taste && si.Tastes?.length > 0">The {{item.Name}} tastes
                                        <span *ngFor="let taste of si.Tastes; let k = index;">
                                            {{taste.Taste}}<span
                                                *ngIf="k+1 != si.Tastes?.length && k+2 != si.Tastes?.length">,
                                            </span><span *ngIf="k+2 == si.Tastes?.length"> and </span></span>.</span>
                                    <span *ngIf="si.EdibleParts[0].Part && si.EdibleParts?.length > 0">
                                        Its <span *ngFor="let part of si.EdibleParts; let k = index;">
                                            {{part.Part}}<span
                                                *ngIf="k+1 != si.EdibleParts?.length && k+2 != si.EdibleParts?.length">,
                                            </span><span *ngIf="k+2 == si.EdibleParts?.length"> and </span></span>
                                    can be consumed as Food or used for Medicinal purposes.</span>
                                </p>
                                <p *ngIf="si.MedicinalProperties && si.MedicinalProperties[0].MedicinalProperty && si.MedicinalProperties?.length > 0">
                                    The major medicinal properties of {{item.Name}} are as follows:
                                </p>
                                <ul *ngIf="si.MedicinalProperties && si.MedicinalProperties[0].MedicinalProperty && si.MedicinalProperties?.length > 0">
                                    <li *ngIf="highest && highest?.length > 0">Highest: <span *ngFor="let mp of highest; let k = index;">
                                        {{mp.MedicinalProperty}}<span
                                            *ngIf="k+1 != highest?.length && k+2 != highest?.length">,
                                        </span><span *ngIf="k+2 == highest?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="higher && higher?.length > 0">Higher: <span *ngFor="let mp of higher; let k = index;">
                                        {{mp.MedicinalProperty}}<span
                                            *ngIf="k+1 != higher?.length && k+2 != higher?.length">,
                                        </span><span *ngIf="k+2 == higher?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="high && high?.length > 0">High: <span *ngFor="let mp of high; let k = index;">
                                        {{mp.MedicinalProperty}}<span
                                            *ngIf="k+1 != high?.length && k+2 != high?.length">,
                                        </span><span *ngIf="k+2 == high?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="normal && normal?.length > 0">Normal: <span *ngFor="let mp of normal; let k = index;">
                                        {{mp.MedicinalProperty}}<span
                                            *ngIf="k+1 != normal?.length && k+2 != normal?.length">,
                                        </span><span *ngIf="k+2 == normal?.length"> and </span></span>
                                    </li>
                                </ul>
                                <p *ngIf="si.NutrientSources && si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    The major nutrients available in {{item.Name}} are as follows:
                                </p>
                                <ul *ngIf="si.NutrientSources && si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    <li *ngIf="highestNut && highestNut?.length > 0">Super-Rich in <span *ngFor="let mp of highestNut; let k = index;">
                                            {{mp.Nutrient}}<span
                                                *ngIf="k+1 != highestNut?.length && k+2 != highestNut?.length">,
                                            </span><span *ngIf="k+2 == highestNut?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="higherNut && higherNut?.length > 0">Most-Rich in <span *ngFor="let mp of higherNut; let k = index;">
                                            {{mp.Nutrient}}<span
                                                *ngIf="k+1 != higherNut?.length && k+2 != higherNut?.length">,
                                            </span><span *ngIf="k+2 == higherNut?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="highNut && highNut?.length > 0">High-Rich in <span *ngFor="let mp of highNut; let k = index;">
                                            {{mp.Nutrient}}<span *ngIf="k+1 != highNut?.length && k+2 != highNut?.length">,
                                            </span><span *ngIf="k+2 == highNut?.length"> and </span></span>
                                    </li>
                                    <li *ngIf="normalNut && normalNut?.length > 0">Rich in <span *ngFor="let mp of normalNut; let k = index;">
                                            {{mp.Nutrient}}<span
                                                *ngIf="k+1 != normalNut?.length && k+2 != normalNut?.length">,
                                            </span><span *ngIf="k+2 == normalNut?.length"> and </span></span>
                                    </li>
                                </ul>
                            </div>
                            <div class="matter" *ngIf="share.home.language == 'Hindi'">
                                <p>
                                    {{item.NameHindi}}<span *ngIf="si.OtherNamesHin && si.OtherNamesHin?.length">, जिसे
                                        {{si.OtherNamesHin}} आदि नामों से भी जाना जाता है</span>
                                    <span *ngIf="si.OtherNamesHin && si.OtherNamesHin?.length && si.ScientificNameHindi && si.ScientificNameHindi?.length">;
                                    </span>
                                    <span *ngIf="si.ScientificNameHindi && si.ScientificNameHindi?.length"> का
                                        वैज्ञानिक-नाम {{si.ScientificNameHindi}}<span *ngIf="si.ScientificNameHindi != si.ScientificName"> ({{si.ScientificName}})</span> है</span>।
                                    <span *ngIf="si.FamilyHindi && si.FamilyHindi?.length">यह {{si.FamilyHindi}} <span *ngIf="si.FamilyHindi != si.Family">({{si.Family}})</span> परिवार से सम्बन्धित है।</span><span *ngIf="si.OtherNameEngDev && si.OtherNameEngDev?.length"> इसे अंग्रेजी में
                                        {{si.OtherNameEngDev}}<span *ngIf="si.OtherNameEngDev != si.OtherNameEng"> ({{si.OtherNameEng}})</span> कहते हैं।</span>
                                </p>
                                <p *ngIf="si.NameSansDev && si.NameSansDev?.length">{{item.NameHindi}} को संस्कृत में {{si.NameSansDev}} भी कहते हैं।</p>
                                <p>
                                    <span *ngIf="si.Tastes[0].Taste && si.Tastes?.length">{{item.NameHindi}} का स्वाद
                                        <span *ngFor="let taste of si.Tastes; let k = index;">
                                            {{taste.TasteHindi}}<span
                                                *ngIf="k+1 != si.Tastes?.length && k+2 != si.Tastes?.length">,
                                            </span><span *ngIf="k+2 == si.Tastes?.length"> और </span></span> होता है।
                                    </span>
                                    <span *ngIf="si.EdibleParts[0].Part && si.EdibleParts?.length">
                                        इसके
                                        <span *ngFor="let part of si.EdibleParts; let k = index;">
                                            {{part.PartHindi}}<span
                                                *ngIf="k+1 != si.EdibleParts?.length && k+2 != si.EdibleParts?.length">,
                                            </span><span *ngIf="k+2 == si.EdibleParts?.length"> और </span></span>
                                    को भोजन अथवा औषधि के रूप में उपयोग किया जा सकता है।</span>
                                </p>
                                <p *ngIf="si.MedicinalProperties[0].MedicinalProperty && si.MedicinalProperties?.length > 0">
                                    {{item.NameHindi}} के प्रमुख औषधीय गुण:
                                </p>
                                <ul *ngIf="si.MedicinalProperties[0].MedicinalProperty && si.MedicinalProperties?.length > 0">
                                    <li *ngIf="highest && highest?.length > 0">उच्चतम: <span *ngFor="let mp of highest; let k = index;">
                                        {{mp.MedicinalPropertyHindi}}<span
                                            *ngIf="k+1 != highest?.length && k+2 != highest?.length">,
                                        </span><span *ngIf="k+2 == highest?.length"> और </span></span>
                                    </li>
                                    <li *ngIf="higher && higher?.length > 0">उच्चतर: <span *ngFor="let mp of higher; let k = index;">
                                        {{mp.MedicinalPropertyHindi}}<span
                                            *ngIf="k+1 != higher?.length && k+2 != higher?.length">,
                                        </span><span *ngIf="k+2 == higher?.length"> और </span></span>
                                    </li>
                                    <li *ngIf="high && high?.length > 0">उच्च: <span *ngFor="let mp of high; let k = index;">
                                        {{mp.MedicinalPropertyHindi}}<span
                                            *ngIf="k+1 != high?.length && k+2 != high?.length">,
                                        </span><span *ngIf="k+2 == high?.length"> और </span></span>
                                    </li>
                                    <li *ngIf="normal && normal?.length > 0">सामान्य: <span *ngFor="let mp of normal; let k = index;">
                                        {{mp.MedicinalPropertyHindi}}<span
                                            *ngIf="k+1 != normal?.length && k+2 != normal?.length">,
                                        </span><span *ngIf="k+2 == normal?.length"> और </span></span>
                                    </li>
                                </ul>
                                <p *ngIf="si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    {{item.NameHindi}} में उपलब्ध प्रमुख न्यूट्रिएंट्स इस प्रकार हैं:
                                </p>
                                <ul *ngIf="si.NutrientSources[0].Nutrient && si.NutrientSources?.length > 0">
                                    <li *ngIf="highestNut && highestNut?.length > 0"><span *ngFor="let mp of highestNut; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != highestNut?.length && k+2 != highestNut?.length">,
                                            </span><span *ngIf="k+2 == highestNut?.length"> और </span></span> बहुत अधिक मात्रा में
                                    </li>
                                    <li *ngIf="higherNut && higherNut?.length > 0"><span *ngFor="let mp of higherNut; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != higherNut?.length && k+2 != higherNut?.length">,
                                            </span><span *ngIf="k+2 == higherNut?.length"> और </span></span> अधिक मात्रा में
                                    </li>
                                    <li *ngIf="highNut && highNut?.length > 0"><span *ngFor="let mp of highNut; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != highNut?.length && k+2 != highNut?.length">,
                                            </span><span *ngIf="k+2 == highNut?.length"> और </span></span> उचित मात्रा में
                                    </li>
                                    <li *ngIf="normalNut && normalNut?.length > 0"><span *ngFor="let mp of normalNut; let k = index;">
                                            {{mp.NutrientHindi}}<span
                                                *ngIf="k+1 != normalNut?.length && k+2 != normalNut?.length">,
                                            </span><span *ngIf="k+2 == normalNut?.length"> और </span></span> सामान्य मात्रा में
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!intro || intro?.length == 0 || !intro[0].ScientificName">
                        <div class="matter" *ngIf="share.home.language == 'English'" [innerHTML]="item.Intro">
                        </div>
                        <div class="matter" *ngIf="share.home.language == 'Hindi'" [innerHTML]="item.IntroHindi">
                        </div>
                    </ng-container>
                </div>
                <!-- <hr id="cont" *ngIf="item.Links && item.Links?.length > 0" style="margin: 10px 0 !important"> -->
                <!-- <div [id]="'products-related-to-' + item.Slug + '-on-amazon'" *ngIf="item.Links && item.Links?.length > 0 && showLinks" class="containerDiv">
                    <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Products Related to {{item.Name}} on Amazon</h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">एमाज़ॉन पर {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स</h2>
                    <swiper [config]="getSwiperConfig()">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide" *ngFor="let link of item.Links; let j = index;">
                                <iframe style="width: 120px; height: 240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" [src]="link.Link"></iframe>
                            </div>
                        </div>
                    </swiper>
                </div> -->
                <hr *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0" style="margin: 10px 0 !important">
                <div [id]="'how-to-consume-' + item.Slug" *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">How to consume {{item.Name}}?</h2>
                        <ul>
                            <li *ngFor="let si of item.Modes; let j = index;">
                                {{si.Mode}}
                            </li>
                        </ul>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} कैसे ग्रहण करें?</h2>
                        <ul>
                            <li *ngFor="let si of item.Modes; let j = index;">
                                {{si.ModeHindi}}
                            </li>
                        </ul>
                    </div>
                </div>
                <hr *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0" style="margin: 10px 0 !important">
                <div [id]="'how-much-' + item.Slug + '-in-a-day'" *ngIf="item.Modes[0] && item.Modes[0].Mode?.length > 0" class="containerDiv">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">How much {{item.Name}} to consume in a day?</h2>
                        <ul>
                            <li *ngFor="let si of item.Modes; let j = index;">
                                {{si.Mode}}: {{si.Qty}} {{si.Unit}}
                                <span *ngIf="si.Qty1 && si.Qty1?.length > 0"> ({{si.Qty1}} {{si.Unit1}})</span> - {{si.Frequency}}
                            </li>
                        </ul>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">प्रतिदिन कितनी मात्रा में {{item.NameHindi}} ग्रहण करें?</h2>
                        <ul>
                            <li *ngFor="let si of item.Modes; let j = index;">
                                {{si.ModeHindi}}: {{si.QtyHindi}} {{si.UnitHindi}}
                                <span *ngIf="si.QtyHindi1 && si.QtyHindi1?.length > 0"> ({{si.QtyHindi1}} {{si.UnitHindi1}})</span> - {{si.FrequencyHindi}}
                            </li>
                        </ul>
                    </div>
                </div>
                <hr style="margin: 10px 0 !important;">
                <div class="containerDiv">
                    <img class="ingImg" [src]="'assets/images/herbs/herbs-secondary/' + item.Slug + '-1.jpg'" />
                </div>
                <hr *ngIf="item.Synonyms && item.Synonyms?.length > 0 || item.SynonymsHindi && item.SynonymsHindi?.length > 0" style="margin: 10px 0 !important;">
                <div [id]="'other-names-of-' + item.Slug" *ngIf="item.Synonyms && item.Synonyms?.length > 0 || item.SynonymsHindi && item.SynonymsHindi?.length > 0" class="containerDivBlock">
                    <div class="matter" *ngIf="share.home.language == 'English'">
                        <h2 style="color: #03A9F4">Other Names of {{item.Name}}</h2>
                        <div class="synonyms" [innerHTML]="item.Synonyms"></div>
                    </div>
                    <div class="matter" *ngIf="share.home.language == 'Hindi'">
                        <h2 style="color: #03A9F4">{{item.NameHindi}} के अन्य नाम</h2>
                        <div class="synonyms" [innerHTML]="item.SynonymsHindi"></div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-4 col-12">
                <app-daily-product></app-daily-product>
                <app-daily-post></app-daily-post>
                <div [id]="'videos-about-' + item.Slug" class="containerDiv" *ngIf="item.Videos[0].MediaPath && item.Videos?.length > 0">
                    <hr style="margin-top:0px">
                    <h2 *ngIf="share.home.language == 'English'" style="text-align: center;color: #03A9F4">Videos on {{item.Name}}
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="text-align: center;color: #03A9F4">
                        {{item.NameHindi}} पर वीडियोज़</h2>
                    <div *ngFor="let video of item.Videos; let j = index">
                        <div [id]="videoSlug[j]">
                            <h6 *ngIf="share.home.language == 'English'" style="margin:10px 0">{{video.Title}}</h6>
                            <h6 *ngIf="share.home.language == 'Hindi'" style="margin:10px 0">{{video.TitleHindi}}</h6>
                            <video style="width:100%" [poster]="'assets/videos/herbs/' + video.MediaPoster" controls playsinline crossorigin="anonymous">
                                <source [src]="'assets/videos/herbs/' + video.MediaPath" type="video/mp4" />
                            </video>
                            <div style="font-size: small; font-style: italic; color: grey">Courtesy: {{video.Source}}
                            </div>
                        </div>
                        <hr *ngIf="j+1 != item.Videos?.length">
                    </div>
                </div>
                <hr *ngIf="otherNames && otherNames?.length > 0" style="margin-top:50px">
                <div [id]="'different-names-of-' + item.Slug" *ngIf="otherNames && otherNames?.length > 0">
                    <h2 *ngIf="share.home.language == 'English'" style="text-align: center;color: #03A9F4;font-weight: normal;">Different Names of<br/>{{item.Name}}
                    </h2>
                    <h2 *ngIf="share.home.language == 'Hindi'" style="text-align: center;color: #03A9F4;font-weight: normal;line-height: 164%;">{{item.NameHindi}}<br/>के विभिन्न नाम
                    </h2>
                    <div class="containerDivBlock" style="margin-top: 10px !important">
                        <div *ngFor="let name of otherNames; let k = index" style="text-align: center;">
                            <h6 style="color: #03A9F4" *ngIf="share.home.language == 'English'">
                                {{item.Name}} in {{name.Language}}
                            </h6>
                            <h6 style="color: #03A9F4" *ngIf="share.home.language == 'Hindi'">
                                {{name.LanguageHindi}} में {{item.NameHindi}} का नाम
                            </h6>
                            <div *ngFor="let sn of name.Names; let l = index;" class="otherLangNames">
                                <div>{{sn.Devanagari}}</div>
                                <div>|</div>
                                <div>{{sn.Roman}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <div *ngIf="item.Links && item.Links?.length > 0" class="container">
        <hr style="margin: 10px 0 !important">
        <div class="containerDiv">
            <h2 *ngIf="share.home.language == 'English'" style="color: #03A9F4">Products Related to {{item.Name}} on Amazon</h2>
            <h2 *ngIf="share.home.language == 'Hindi'" style="color: #03A9F4">एमाज़ॉन पर {{item.NameHindi}} से सम्बंधित प्रोडक्ट्स</h2>
            <span *ngFor="let link of item.Links;">
                <iframe style="width:120px;height:240px;margin: 0 9px 10px" marginwidth="0" marginheight="0" scrolling="no"
                    frameborder="0" [src]="link.Link"></iframe>
            </span>
        </div>
    </div> -->
</div>
<app-scroll-top></app-scroll-top>
<app-footer></app-footer>