import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FoodsIndexComponent } from './foods-index/foods-index.component';
import { HerbsIndexComponent } from './herbs-index/herbs-index.component';
import { HerbsComponent } from './herbs/herbs.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { NutrientsComponent } from './nutrients/nutrients.component';
import { SearchComponent } from './search/search.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';

const routes: Routes = [
  { path: 'search', component: SearchComponent },
  { path: 'not-found', component: NotFoundComponent },
  { path: 'foods-index/:startingWith', component: FoodsIndexComponent },
  { path: 'foods-index', redirectTo: '/foods-index/starting-with-A', pathMatch: 'full' },
  { path: 'herbs-index/:startingWith', component: HerbsIndexComponent },
  { path: 'herbs-index', redirectTo: '/herbs-index/starting-with-A', pathMatch: 'full' },
  { path: 'herbs', redirectTo: '/herbs-index/starting-with-A', pathMatch: 'full' },
  { path: 'herbs/:slug', component: HerbsComponent },
  { path: 'foods', redirectTo: '/foods-index/starting-with-A', pathMatch: 'full' },
  { path: 'foods/:slug', component: SuggestionsComponent },
  { path: 'nutrients/:slug', component: NutrientsComponent },
  { path: '', redirectTo: '/foods-index/starting-with-A', pathMatch: 'full' },
  { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
