<header class="header header-two">
    <div class="header-wrapper">
        <div class="container">
            <div class="row web">
                <div class="col-12 col-md-2 col-lg-3 logo-box mobilelogo webmenu">
                    <div class="logo">
                        <img style="height:60px; padding-top:5px" src="assets/images/Wellness-Web-Portal-Logo.webp"/>
                    </div>
                </div>
                <div class="col-12 col-md-10 col-lg-9 webmenu" style="float: right">
                    <div class="navbar-expand">
                        <ul class="navbar-nav">
                            <li class="nav-item" ngbDropdown>
                                <a class="nav-link" id="cta" ngbDropdownToggle>Call to Actions</a>
                                <div ngbDropdownMenu aria-labelledby="cta">
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/get-guidance-health-seekers/" target="_blank">Seekers' Guidance</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/get-registered-wellness-providers/" target="_blank">Providers' Registration</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/subscribe-our-newsletter/" target="_blank">Subscribe Newsletter</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/contact/" target="_blank">Contact Us</a>
                                    <li ngbDropdown style="padding:4px 24px">
                                        <a id="aaq" ngbDropdownToggle style="width:100%">Ask a Question</a>
                                        <div ngbDropdow nMenu aria-labelledby="aaq">
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/get-registered-wellness-providers/" target="_blank">Health Seekers</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/ask-a-question-wellness-providers/" target="_blank">Wellness Providers</a>
                                        </div>
                                    </li>
                                </div>
                            </li>
                            <li class="nav-item" ngbDropdown>
                                <a class="nav-link" id="pr" ngbDropdownToggle>Providers</a>
                                <div ngbDropdownMenu aria-labelledby="pr">
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/wellness-providers-abc/" target="_blank">Providers' ABC</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/frequently-asked-questions-wellness-providers/" target="_blank">FAQs</a>
                                </div>
                            </li>
                            <li class="nav-item" ngbDropdown>
                                <a class="nav-link" id="seek" ngbDropdownToggle>Seekers</a>
                                <div ngbDropdownMenu aria-labelledby="seek">
                                    <li ngbDropdown style="padding:4px 24px">
                                        <a id="fc" ngbDropdownToggle style="width:100%">Find Centers</a>
                                        <div ngbDropdow nMenu aria-labelledby="fc">
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/wellness-centers-of-natural-therapies-india/" target="_blank">In India</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/wellness-centers-of-natural-therapies-worldwide/" target="_blank">World Wide</a>
                                        </div>
                                    </li>
                                    <li ngbDropdown style="padding:4px 24px">
                                        <a id="ss" ngbDropdownToggle style="width:100%">Success Stories</a>
                                        <div ngbDropdow nMenu aria-labelledby="ss">
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/endorsement-of-wellness-natural-therapies/" target="_blank">Endorsements</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/celebrity-testimonies-of-wellness-therapies/" target="_blank">Testimonies</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/case-studies-of-natural-wellness-therapies/" target="_blank">Case Studies</a>
                                        </div>
                                    </li>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/most-common-diseases/" target="_blank">Know Diseases</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/health-seekers-abc/" target="_blank">Seekers' ABC</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/frequently-asked-questions-health-seekers/" target="_blank">FAQs</a>
                                    
                                </div>
                            </li>
                            <li class="nav-item" ngbDropdown>
                                <a class="nav-link" id="therapies" ngbDropdownToggle>Therapies</a>
                                <div ngbDropdownMenu aria-labelledby="therapies">
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/natural-wellness-therapies/" target="_blank">Wellness Therapies</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/why-wellness-therapies/" target="_blank">Why Go Natural</a>
                                    <li ngbDropdown style="padding:4px 24px">
                                        <a id="therapies-list" ngbDropdownToggle>Therapies List</a>
                                        <div ngbDropdow nMenu aria-labelledby="therapies-list">
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/acupressure/" target="_blank">Acupressure</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/acupuncture/" target="_blank">Acupuncture</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/ayurveda/" target="_blank">Ayurveda</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/chiropractic/" target="_blank">Chiropractic</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/integrated-medicine-or-holistic-medicine/" target="_blank">Integrated Medicine</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/meditation/" target="_blank">Meditation</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/naturopathy/" target="_blank">Naturopathy</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/nutrition-diet/" target="_blank">Nutrition and Diet</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/panchkarma/" target="_blank">Panchkarma</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/physiotherapy/" target="_blank">Physiotherapy</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/pranic-healing/" target="_blank">Pranic Healing</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/reiki/" target="_blank">Reiki</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/siddha/" target="_blank">Siddha</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/traditional-chinese-medicines-tcm/" target="_blank">TCM</a>
                                            <a ngbDropdownItem href="https://wellnesswebportal.com/yoga/" target="_blank">Yoga</a>
                                        </div>
                                    </li>
                                </div>
                            </li>
                            <li class="nav-item" ngbDropdown>
                                <a class="nav-link" ngbDropdownToggle>Portal</a>
                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/about/" target="_blank">About Us</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/portal-wellness-web-portal/" target="_blank">About Portal</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/how-wellness-web-portal-works/" target="_blank">How It Works</a>
                                    <a ngbDropdownItem href="https://wellnesswebportal.com/blog/" target="_blank">Blog</a>
                                </div>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="https://wellnesswebportal.com/" [target]="target">Home</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="col-12 col-md-3 col-lg-3 mobilelogo mobilemenu">
                    <select class="form-control" (change)="goToMenu()" name="menuOption" [(ngModel)]="menuOption">
                        <option value="https://aumswow.com/blogs/">Blogs</option>
                        <option value="https://aumswow.com/media-posts/">Media</option>
                        <option value="https://foodandherbs.wellnesswebportal.com/">Life-Veda</option>
                        <option value="mailto:contact@aumswow.com?subject=Contact from AumsWow Life-Veda">Contact</option>
                    </select>
                </div> -->
            </div>
            <div class="row mobile" style="align-items: center">
                <div class="col-10 logo-box">
                    <div class="logo">
                        <img style="height:60px; padding-top:10px" src="assets/images/Wellness-Web-Portal-Logo.webp"/>
                    </div>
                </div>
                <div class="col-2" style="text-align: center;">
                    <i (click)="showMobileMenu()" style="font-size: 20px;" id="mm" class="fa fa-bars menu-bars"></i>
                </div>
            </div>
            <div class="mobilem" *ngIf="showMenu">
                <span class="close" (click)="closeMobileMenu()" ><i class="fa fa-times"></i> </span>
                <div class="mobilemOpt" style="border-top: 0 !important">
                    <a href="https://wellnesswebportal.com/" target="_blank" (click)="closeMobileMenu()">Home</a>
                </div>
                <!-- Portal Toggle -->
                <div class="mobilemOpt" (click)="toggleAbout()">
                    Portal&nbsp;<i *ngIf="!aboutToggle" class="fa fa-caret-right"></i> <i *ngIf="aboutToggle" class="fa fa-caret-down"></i> 
                </div>
                <ng-container *ngIf="aboutToggle">
                <div class="mobilemOptSub">
                    <a href="https://wellnesswebportal.com/about/" target="_blank" (click)="closeMobileMenu()">About Us</a>
                </div>
                <div class="mobilemOptSub">
                    <a href="https://wellnesswebportal.com/portal-wellness-web-portal/" target="_blank" (click)="closeMobileMenu()">About Portal</a>
                </div>
                <div class="mobilemOptSub">
                    <a href="https://wellnesswebportal.com/how-wellness-web-portal-works/" target="_blank" (click)="closeMobileMenu()">How It Works</a>
                </div>
                <div class="mobilemOptSub">
                    <a href="https://wellnesswebportal.com/blog/" target="_blank" (click)="closeMobileMenu()">Blog</a>
                </div>
                </ng-container>
                <!-- Therapies Toggle -->
                <div class="mobilemOpt" (click)="toggleTherapies()">
                    Therapies&nbsp;<i *ngIf="!therapiesToggle" class="fa fa-caret-right"></i> <i *ngIf="therapiesToggle" class="fa fa-caret-down"></i> 
                </div>
                <ng-container *ngIf="therapiesToggle">
                <div class="mobilemOptSub">
                    <a  href="https://wellnesswebportal.com/natural-wellness-therapies/" target="_blank" (click)="closeMobileMenu()">Wellness Therapies</a>
                </div>
                <div class="mobilemOptSub">
                    <a href="https://wellnesswebportal.com/why-wellness-therapies/" target="_blank" (click)="closeMobileMenu()">Why Go Natural</a>
                </div>
                <div class="mobilemOptSub" (click)="toggleTherapiesL()">
                    Therapies List&nbsp;<i *ngIf="!therapiesLToggle" class="fa fa-caret-right"></i> <i *ngIf="therapiesLToggle" class="fa fa-caret-down"></i> 
                </div>
                <ng-container *ngIf="therapiesLToggle">
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/acupressure/" target="_blank" (click)="closeMobileMenu()">Acupressure</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/acupuncture/" target="_blank" (click)="closeMobileMenu()">Acupuncture</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/ayurveda/" target="_blank" (click)="closeMobileMenu()">Ayurveda</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/chiropractic/" target="_blank" (click)="closeMobileMenu()">Chiropractic</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/integrated-medicine-or-holistic-medicine/" target="_blank" (click)="closeMobileMenu()">Integrated Medicine</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/meditation/" target="_blank" (click)="closeMobileMenu()">Meditation</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/naturopathy/" target="_blank" (click)="closeMobileMenu()">Naturopathy</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/nutrition-diet/" target="_blank" (click)="closeMobileMenu()">Nutrition and Diet</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/panchkarma/" target="_blank" (click)="closeMobileMenu()">Panchkarma</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/physiotherapy/" target="_blank" (click)="closeMobileMenu()">Physiotherapy</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/pranic-healing/" target="_blank" (click)="closeMobileMenu()">Pranic Healing</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/reiki/" target="_blank" (click)="closeMobileMenu()">Reiki</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/siddha/" target="_blank" (click)="closeMobileMenu()">Siddha</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/traditional-chinese-medicines-tcm/" target="_blank" (click)="closeMobileMenu()">TCM</a>
                </div>
                <div class="mobilemOptSubSub">
                    <a href="https://wellnesswebportal.com/yoga/" target="_blank" (click)="closeMobileMenu()">Yoga</a>
                </div>
                </ng-container>
                </ng-container>
                <!-- Seekers Toggle -->
                <div class="mobilemOpt" (click)="toggleSeeker()">
                    Seekers&nbsp;<i *ngIf="!seekerToggle" class="fa fa-caret-right"></i> <i *ngIf="seekerToggle" class="fa fa-caret-down"></i> 
                </div>
                <ng-container *ngIf="seekerToggle">
                    <div class="mobilemOptSub" (click)="toggleFindCenter()">
                        Find Centers&nbsp;<i *ngIf="!findCenterToggle" class="fa fa-caret-right"></i> <i *ngIf="findCenterToggle" class="fa fa-caret-down"></i> 
                    </div>
                    <ng-container *ngIf="findCenterToggle">
                    <div class="mobilemOptSubSub">
                        <a href="https://wellnesswebportal.com/wellness-centers-of-natural-therapies-india/" target="_blank" (click)="closeMobileMenu()">In India</a>
                    </div>
                    <div class="mobilemOptSubSub">
                        <a href="https://wellnesswebportal.com/wellness-centers-of-natural-therapies-worldwide/" target="_blank" (click)="closeMobileMenu()">World Wide</a>
                    </div>
                    </ng-container>

                    <div class="mobilemOptSub" (click)="toggleSuccessStories()">
                        Success Stories&nbsp;<i *ngIf="!successStoriesToggle" class="fa fa-caret-right"></i> <i *ngIf="successStoriesToggle" class="fa fa-caret-down"></i> 
                    </div>
                    <ng-container *ngIf="successStoriesToggle">
                    <div class="mobilemOptSubSub">
                        <a href="https://wellnesswebportal.com/endorsement-of-wellness-natural-therapies/" target="_blank" (click)="closeMobileMenu()">Endorsements</a>
                    </div>
                    <div class="mobilemOptSubSub">
                        <a href="https://wellnesswebportal.com/celebrity-testimonies-of-wellness-therapies/" target="_blank" (click)="closeMobileMenu()">Testimonies</a>
                    </div>
                    <div class="mobilemOptSubSub">
                        <a href="https://wellnesswebportal.com/case-studies-of-natural-wellness-therapies/" target="_blank" (click)="closeMobileMenu()">Case Studies</a>
                    </div>
                    </ng-container>
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/most-common-diseases/" target="_blank" (click)="closeMobileMenu()">Know Diseases</a>
                    </div>
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/health-seekers-abc/" target="_blank" (click)="closeMobileMenu()">Seekers' ABC</a>
                    </div>
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/frequently-asked-questions-health-seekers/" target="_blank" (click)="closeMobileMenu()">FAQs</a>
                    </div>
                    </ng-container>
                    <!-- Providers Toggle -->
                <div class="mobilemOpt" (click)="toggleProviders()">
                    Providers&nbsp;<i *ngIf="!providersToggle" class="fa fa-caret-right"></i> <i *ngIf="providersToggle" class="fa fa-caret-down"></i> 
                </div>
                <ng-container *ngIf="providersToggle">
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/wellness-providers-abc/" target="_blank" (click)="closeMobileMenu()">Providers' ABC</a>
                    </div>
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/frequently-asked-questions-wellness-providers/" target="_blank" (click)="closeMobileMenu()">FAQs</a>
                    </div>
                    </ng-container>
                    <!-- Call to Action Toggle -->
                <div class="mobilemOpt" (click)="toggleCallToAction()">
                    Call to Actions&nbsp;<i *ngIf="!callToActionToggle" class="fa fa-caret-right"></i> <i *ngIf="callToActionToggle" class="fa fa-caret-down"></i> 
                </div>
                <ng-container *ngIf="callToActionToggle">
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/get-guidance-health-seekers/" target="_blank" (click)="closeMobileMenu()">Seekers' Guidance</a>
                    </div>
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/get-registered-wellness-providers/" target="_blank" (click)="closeMobileMenu()">Providers' Registration</a>
                    </div>
                    
                    <div class="mobilemOptSub" (click)="toggleAskQuestion()">
                        Ask a Question&nbsp;<i *ngIf="!askAQuestionToggle" class="fa fa-caret-right"></i> <i *ngIf="askAQuestionToggle" class="fa fa-caret-down"></i> 
                    </div>
                    <ng-container *ngIf="askAQuestionToggle">
                    <div class="mobilemOptSubSub">
                        <a href="https://wellnesswebportal.com/get-registered-wellness-providers/" target="_blank" (click)="closeMobileMenu()">Health Seekers</a>
                    </div>
                    <div class="mobilemOptSubSub">
                        <a href="https://wellnesswebportal.com/ask-a-question-wellness-providers/" target="_blank" (click)="closeMobileMenu()">Wellness Providers</a>
                    </div>
                    </ng-container>

                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/subscribe-our-newsletter/" target="_blank" (click)="closeMobileMenu()">Subscribe Newsletter</a>
                    </div>
                    <div class="mobilemOptSub">
                        <a href="https://wellnesswebportal.com/contact/" target="_blank" (click)="closeMobileMenu()">Contact Us</a>
                    </div>
                    </ng-container>
            </div>
        </div>
    </div>
</header>