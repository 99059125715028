import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from './../common/data.service';
import { ShareService } from './../common/share.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-daily-product',
  templateUrl: './daily-product.component.html',
  styleUrls: ['./daily-product.component.css']
})
export class DailyProductComponent implements OnInit {

  products: any;

  constructor(private ds: DataService,
              public share: ShareService,
              private spinner: NgxSpinnerService,
              private router: Router) { }

  ngOnInit(): void {
    this.getDailyProduct();
  }

  getDailyProduct() {
    this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostAnon",
      {
        ProcedureName: 'GetDailyProduct', SqlParamsArr: [
        ]
      }).subscribe
      (data => {
        if (data) {
          this.products = data;
        }
      });
  }

}
