import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgxSpinnerModule } from 'ngx-spinner'
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DataService } from './common/data.service';
import { ShareService } from './common/share.service';
import { MessageService } from './common/message.service';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopUpWindowComponent } from './popup/popupwindow.component';
import { ToastComponent } from './toast/toast.component';
import { HeaderComponent } from './header/header.component';
import { DailyProductComponent } from './daily-product/daily-product.component';
import { DailyPostComponent } from './daily-post/daily-post.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HerbsComponent } from './herbs/herbs.component';
import { FooterComponent } from './footer/footer.component';
import { FoodsIndexComponent } from './foods-index/foods-index.component';
import { HerbsIndexComponent } from './herbs-index/herbs-index.component';
import { ScrollTopComponent } from './scroll-top/scroll-top.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { SearchComponent } from './search/search.component';
import { NutrientsComponent } from './nutrients/nutrients.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SuggestionsComponent,
    PopUpWindowComponent,
    ToastComponent,
    HeaderComponent,
    DailyProductComponent,
    DailyPostComponent,
    NotFoundComponent,
    HerbsComponent,
    FooterComponent,
    FoodsIndexComponent,
    HerbsIndexComponent,
    ScrollTopComponent,
    SearchBarComponent,
    SearchComponent,
    NutrientsComponent
  ],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    NgxUsefulSwiperModule,
    NgxChartsModule,
    NgxEchartsModule.forRoot({
      echarts,
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    FormsModule
  ],
  providers: [DataService, ShareService, MessageService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
