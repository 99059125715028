import { Component, OnInit, Injectable, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { DataService } from './../common/data.service';
import { ShareService } from './../common/share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwiperOptions } from 'swiper';
import { DomSanitizer, SafeResourceUrl, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-nutrients',
  templateUrl: './nutrients.component.html',
  styleUrls: ['./nutrients.component.css']
})
export class NutrientsComponent implements OnInit {

  slug: string;
  url: string;
  config: SwiperOptions;

  details = [];

  veg = [];
  fruits = [];
  spices = [];
  grains = [];
  pulses = [];
  dairy = [];

  isCollapsed: Array<any> = [];

  showLinks = false;

  lang: string;

  constructor(private ds: DataService,
    public share: ShareService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private pageTitle: Title,
    @Inject(DOCUMENT) private doc) { }

  ngOnInit(): void {
    this.slug = this.aRoute.snapshot.params['slug'];
    let urlparam = new URLSearchParams(window.location.search);
    if (urlparam.has('lang')) {
      let lang = urlparam.get('lang')
      if (lang == 'hi') {
        this.share.home.language = 'Hindi'
        this.lang = "?lang=hi";
      }
    }
    else {
      this.share.home.language = 'English'
      this.lang = "?lang=en";
    }
    this.getDetails()
  }

  goTo(i) {
    window.location.href = window.location.href.replace(window.location.hash, "") + i;
  }

  getDetails() {
    this.spinner.show()
    this.ds.createService(environment.apiURL + "Auth/ExecuteProcedureMultiPostJSONAnon",
      {
        ProcedureName: 'GetNutrientDetail', SqlParamsArr: [
          { ParamName: '@Slug', ParamValue: this.slug }
        ]
      }).subscribe
      (data => {
        if (data) {
          this.details = data;
          this.share.home.name = data[0].Name;
          if (data[0].Diseases) {
            this.isCollapsed = new Array(data[0].Diseases.length);
          }
          this.createLinkForCanonicalURL(data[0].Source);
          if (this.details[0].Links) {
            for (let i = 0; i < this.details[0].Links.length; i++) {
              if (matchMedia('(display-mode: fullscreen)').matches || matchMedia('(display-mode: standalone)').matches || matchMedia('(display-mode: minimal-ui)').matches) {
                this.details[0].Links[i].Link = this.details[0].Links[i].Link + "false";
                this.details[0].Links[i].Link = this.sanitizer.bypassSecurityTrustResourceUrl(this.details[0].Links[i].Link);
              }
              else {
                this.details[0].Links[i].Link = this.details[0].Links[i].Link + "true";
                this.details[0].Links[i].Link = this.sanitizer.bypassSecurityTrustResourceUrl(this.details[0].Links[i].Link);
              }
              this.showLinks = true;
            }
          }
          if (data[0].Ingredients) {
            this.veg = data[0].Ingredients.filter((item) => item.IngredientType === 6);
            this.fruits = data[0].Ingredients.filter((item) => item.IngredientType === 2);
            this.spices = data[0].Ingredients.filter((item) => item.IngredientType === 4);
            this.grains = data[0].Ingredients.filter((item) => item.IngredientType === 3);
            this.pulses = data[0].Ingredients.filter((item) => item.IngredientType === 5);
            this.dairy = data[0].Ingredients.filter((item) => item.IngredientType === 1);
          }
          if (this.share.home.language == 'Hindi') {
            this.pageTitle.setTitle(data[0].NameHindi + " के स्वास्थ्य लाभ और वो सब कुछ, जो आपको जानना चाहिए - AumsWow Wellness");
          }
          else {
            this.pageTitle.setTitle(data[0].Name + ": Health Benefits and everything that you need to know about - AumsWow Wellness");
          };
          this.spinner.hide()
        }
        else {
          this.spinner.hide()
          this.router.navigate(['/not-found'], { skipLocationChange: true })
        };
      });
  }

  createLinkForCanonicalURL(Source) {
    if (document.getElementById('can')) {
      document.getElementById('can').remove();
    }
    let link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('id', 'can');
    link.setAttribute('href', location.href.replace(location.hash, ""));
  }

  getSwiperConfig(): SwiperOptions {
    var cont = document.getElementById("cont").offsetWidth - 40;
    var width = Math.round(cont / 140);
    var config: SwiperOptions = {
      direction: 'horizontal',
      slidesPerView: width,
      slidesPerGroup: 1,
      autoplay: {
        delay: 3000,
        disableOnInteraction: true
      },
      speed: 1000,
      loop: false,
      loopFillGroupWithBlank: true,
      spaceBetween: 10 // Space between each Item
    };

    return this.config = config;
  }
}